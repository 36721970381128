// 1. 引入
import axios from "axios";
import { Notification, Message } from "element-ui";
import qs from "qs";
import { axiosEnum } from "@/api/enum";
import { getToken, logout, getCompanyId, getRole } from "./auth";
import LackAccountDialog from "./register-lack-accout-dialog";

// 取消请求
// let pending = [];
// const CancelToken = axios.CancelToken;
// const removePending = (config) => {
//     for (let p in pending) {
//         if (pending[p].url === config.url + "&" + config.method) {
//             pending[p].cancel("request canceled");
//             pending.splice(p, 1);
//         }
//     }
// };

// 接口错误处理函数
const errorHandler = (response) => {
    const responseConfig = response.config;
    const responseCode = response.data.code;
    const { showErrorMessage = true } = responseConfig;
    // 处理类似船舶定位接口报错信息返回异常,如果没有报错信息，则前端自己处理报错
    const message = response.data.msg || response.data.message;
    if (message && message !== "success" && message !== "SUCCESS") {
        if (showErrorMessage && responseCode !== 409) {
            Notification.error({
                title: message,
                duration: 2500,
            });
        }
        const curToken = getToken();
        if (curToken) {
            setTimeout(() => {
                if (responseCode === 401 || responseCode === 403) {
                    // 接口返回401和403的时候自动登出
                    logout();
                }
            }, 500);
        }
        return Promise.reject(new Error(message || "Error"));
    }
    return response;
};

// 2. 配置信息
const BASE_URL = axiosEnum[process.env.VUE_APP_API_ENV];
const EXCEL_MODEL_URL = `${BASE_URL}/blno/downLoad/excel/`; // 下载Excel模板链接
const EXCEL = `${BASE_URL}/blno/downLoad/excel/`;
const config = {
    // 每次请求的协议、IP地址。  设置该配置后，每次请求路径都可以使用相对路径，例如"/admin/login"
    baseURL: BASE_URL,
    // 请求超时时间
    timeout: 30000,
    // 每次请求携带cookie
    // withCredentials: true,
    // 自定义请求头
    // headers: { "Authorization": store.state.token },
};

// 3. 创建实例
const instance = axios.create(config);
// request拦截器
let requestList = new Set(); // 存储请求url
instance.interceptors.request.use(
    (config) => {
        // removePending(config);
        // config.cancelToken = new CancelToken((c) => {
        //     pending.push({ cancel: c, url: config.url + "&" + config.method });
        // });
        if (!config.headers["Content-Type"]) {
            config.headers["Content-Type"] = "application/json;charset=utf-8";
        }
        if (getToken() || config.headers["Authorization"]) {
            config.headers.Authorization =
                config.headers["Authorization"] || getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        // 利用cancelToken 取消当次请求
        config.cancelToken = new axios.CancelToken((e) => {
            // 在这里阻止重复请求，上个请求未完成时，相同的请求不会再次执行
            requestList.has({ url: config.url, data: config.data })
                ? e(`${location.host}${config.url}---重复请求被中断`)
                : requestList.add({ url: config.url, data: config.data });
        });
        return config;
    },

    (error) => {
        Promise.reject(error);
    },
);
// respone拦截器

instance.interceptors.response.use(
    (response) => {
        // 相同请求不得在600毫秒内重复发送，反之继续执行
        setTimeout(() => {
            requestList.delete(response.config.url);
        }, 600);
        // removePending(response.config);
        const responseCode = response.data.code;
        const responseStatus = response.data.status;
        const responseData = response.data.data;
        const responseConfig = response.config;
        const { onlyJudgeCode } = responseConfig;
        if (onlyJudgeCode) {
            // 只判断code等于200即为接口请求成功
            if (responseCode === 200) {
                return response;
            } else {
                return errorHandler(response);
            }
        }
        // 余额不足,并且不是校验免费余额
        else if (
            responseCode === 402 &&
            !response.request.responseURL.includes(
                "pay/account/user/verFreeBalanceEn",
            )
        ) {
            const isMobile =
                window.location.pathname.indexOf("wxtruckmap") !== -1;
            if (!isMobile) {
                LackAccountDialog.install();
            } else {
                Notification.error({
                    title: response.data.message,
                    duration: 2000,
                });
            }
            return Promise.reject("余额不足");
        } else if (
            (responseCode && responseCode !== 200) ||
            (responseStatus !== undefined && !responseStatus) ||
            !responseData
        ) {
            // 需判断code等于200且data不为空
            return errorHandler(response);
        }
        return response;
    },
    (error) => {
        console.log("拦截器error", error);
        let code = 0;
        // 请求如果失败了，从列表里面删掉，否则请求拦截器会取消请求
        error?.config?.url &&
            requestList.delete({
                url: error.config?.url,
                data: error.config?.data,
            });
        try {
            code = error.response.status;
        } catch (e) {
            if (error.toString().indexOf("Error: timeout") !== -1) {
                Notification.error({
                    title: "网络请求超时",
                    duration: 2500,
                });
                return Promise.reject(error);
            }
            if (error.toString().indexOf("Error: Network Error") !== -1) {
                Notification.error({
                    title: "网络请求错误",
                    duration: 2500,
                });
                return Promise.reject(error);
            }
        }
        if (code === 404) {
            Notification.error({
                title: `服务器异常${code}`,
                duration: 2500,
            });
            return Promise.reject(error);
        }
        const errorMsg =
            error?.response?.data?.message || error?.response?.data;
        if (errorMsg !== undefined) {
            Notification.error({
                title: errorMsg,
                duration: 2500,
            });
        }

        return Promise.reject(error);
    },
);

/*
 * request方法（统一axios请求方法的格式）
 * url       请求URL
 * type      请求类型
 * data      参数
 * isForm    是否表单数据
 * isQs      是否为字符串类型
 */
export const request = async (
    url = "",
    type = "GET",
    data = {},
    otherOptions = {},
    isForm = false,
    isQs = false,
    isNeedFee = false,
) => {
    let result;
    type = type.toUpperCase();
    const requestOptions = {
        method: type,
        url,
        ...otherOptions,
        baseURL: otherOptions?.baseURL ?? BASE_URL,
    };
    if (isForm) {
        const form = new FormData();
        Object.keys(data).forEach((key) => {
            const value = data[key];
            if (Array.isArray(value)) {
                value.forEach((item) => {
                    form.append(key, item);
                });
            } else {
                form.append(key, data[key]);
            }
        });
        data = form;
    }
    if (isQs) {
        data = qs.stringify(data);
    }
    if (!requestOptions.headers) {
        requestOptions.headers = {
            "Content-type": isForm
                ? "multipart/form-data"
                : isQs
                ? "application/x-www-form-urlencoded"
                : "application/json",
        };
    }
    // 如果需要费用
    if (isNeedFee) {
        data = { ...data, companyId: getCompanyId(), role: getRole() };
    }

    if (type === "GET") {
        requestOptions.params = data;
    } else {
        requestOptions.data = data;
    }
    await instance(requestOptions).then((res) => {
        result = res;
    });
    return result;
};

// 4. 导出
export default { instance, BASE_URL, EXCEL_MODEL_URL, EXCEL };
