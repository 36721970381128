import request from "./http";

/**
 * 获取当前用户信息
 * @param {string} userId
 * @returns
 */
export const getCurrentUser = async () => {
    return await request.post("/user/currentUser", {}, {}, false, true);
};

/**
 * 分配超级管理员 (暂未使用，如果用了记得把括号里的东西删掉)
 * @param {string} uuid
 * @param {string} channelCode
 * @param {string} suid
 * @returns
 */
export const comAddEntUser = async (params) => {
    return await request.post("/user/ComaddEntuser", params);
};

/**
 * 用户是否注册
 * @param {string | number} userId
 * @returns
 */
export const userIsRegister = async (params) => {
    return await request.get("/user/IsRegister", params);
};

/**
 * 用户注册
 * @param {number} userId
 * @param {string} phone
 * @param {string} openid
 * @param {string} name
 * @param {string} qqNumber
 * @param {string} pwd
 * @param {string} email
 * @returns
 */
export const userRegister = async (params) => {
    return await request.post("/user/Register", params);
};

/**
 * 修改用户名
 * @param {string} name
 * @param {string} email
 * @param {qqNumber} qqNumber
 * @returns
 */
export const updateUserInfo = async (params) => {
    return await request.post("/user/UpdateUserInfo", params);
};

/**
 * 修改qq或者邮箱
 * @param {number} id

 * @returns
 */
export const userUpdateQQEmail = async (params) => {
    return await request.post("/user/UpdateUserQQEmail", params);
};

/**
 * 用户忘记密码
 * @param {string} phone
 * @param {string} pwd
 * @returns
 */
export const userForgetPwd = async (params) => {
    return await request.post("/user/ForgetPwd", params);
};

// 用户更换密码
/**
 *
 * @param {number} id
 * @param {string} oldpassword
 * @param {string} newpassword
 * @returns
 */
export const userUpdatePwd = async (params) => {
    return await request.post("/user/UpdateUserPwd", params);
};

/**
 * 用户修改头像
 * @param {number} id
 * @param {string} avatar
 * @returns
 */
export const userUpdateAva = async (params) => {
    return await request.post("/user/UpdateUserAva", params);
};

/**
 * 员工申请加入
 * @param {number} uid
 * @param {number} cid
 * @returns
 */
export const staffApplyJoin = async (params) => {
    return await request.post("/user/UserinterComCheck", params);
};

/**
 * 当前用户所申请的公司
 * @param {string} id
 * @returns
 */
export const getUserApplyCompany = async (params) => {
    return await request.get("/user/CurUserComState", params);
};
