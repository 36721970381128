export default
    [
        {
            label: "用户中心",
            command: "user_center",
            img: require("@/assets/img/menu/user_center_select.png"),
            route: "/user/userinfo",
        },
        {
            label: "我的财务",
            command: "my_finance",
            img: require("@/assets/img/menu/finance_select.png"),
            route: "/pay/charge",
        },
        {
            label: "切换身份",
            command: "switch_role",
            img: require("@/assets/img/menu/id_change.png"),
        },
        {
            label: "企业认证",
            command: "company_certify",
            img: require("@/assets/img/menu/company_select.png"),
            route: "/user/ent-company",
        },
        // {
        //     label: "预录单设置",
        //     command: "pre_sheet",
        //     img: require("@/assets/img/menu/pre_sheet_select.png"),
        //     route: "/user/pre-sheet",
        // },
        {
            label: "退出",
            command: "quit",
            img: require("@/assets/img/menu/login_out.png"),
        },
    ]
