<template>
    <el-dialog
        :visible.sync="dialogVisible"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        class="normal_dialog_layout"
        @confirm="()=>{
            this.$emit('confirm')
        }"
        @close="()=>{
            this.$emit('close')
        }"
        @cancel="()=>{
            this.$emit('cancel')
        }"
        attach-to-body
        v-bind="$attrs"
    >
        <template slot="title">
            <div
                class="header-title"
                :style="{
                    height: height + 'px',
                }"
            >
                <slot name="title"></slot>
                <img :src="popupRight" class="pop-right" alt="" width="250" />
            </div>
        </template>
        <slot name="content"></slot>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        height: {
            type: Number,
            default: 65,
        },
    },
    data() {
        return {
            popupRight: require("@/assets/img/login/pupup_right.png"),
        };
    },
    computed: {
        dialogVisible: {
            get() {
                return this.visible;
            },
            set(val) {
                this.$emit("update:visible", val);
            },
        },
    },
};
</script>
<style lang="stylus">
.three-step {
    .pre-btn {
        background-color: #de5d40 !important;
        color: white !important;
        border-radius: 7.5px;
    }
    .next-btn {
        color: white !important;
        background: linear-gradient(to right, #e5917e, #daacb9) !important;
        border-radius: 7.5px;
        width: 80px;
    }
    .btn {
        border-radius: 7.5px;
        width: 80px;
        background-color: #c0c4cc !important;
        color: white !important;
    }

    .body,.el-form-item__label {
        color: #b87100;
        font-size: 16px;
        font-weight: bold;
    }

    .el-input__inner:hover {
        border-color: #b87100;
    }
    .el-input__inner:focus {
        border-color: #b87100;
    }

    .el-textarea__inner:hover {
        border-color: #b87100;
    }

    .el-textarea__inner:focus {
        border-color: #b87100;
    }

    .is-process {
        color: #b87100;
        .is-text {
            border-color: #b87100 !important;
        }
    }
    .el-step__head.is-process {
        border-color: #b87100;
    }
    .el-step__title {
        max-width: 90% !important;
    }
    .el-steps--simple {
        padding-left: 10px;
        padding-right: 10px;
        background-color: #ffefef;
        .first-step {
            flex-basis: 40% !important;
        }
        .last-step {
            flex-basis: 30% !important;
        }
    }
    .font_step {
        margin-bottom: 15px;
    }

    .content-mess{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }
    .el-radio__input.is-checked+.el-radio__label{
        color: #b87100;
    }
    .el-radio__input.is-checked .el-radio__inner{
        border-color: #b87100;
        background: #b87100;
    }
}
</style>
<style lang="stylus" scoped>
.normal_dialog_layout{
    .header-title{
        background: linear-gradient(to right, #de5d40, #e5917e, #daacb9);
        border-radius:10px 10px 0 0;
        position:relative;
        .pop-right{
            position:absolute;
            right:0;
        }
    }
    /deep/ .el-dialog{
        border-radius: 10px;
    }
    /deep/.el-dialog__header {
        padding: 0
      }
      /deep/.el-dialog__body {
        text-align: initial;
        padding: 30px 25px 30px;
        background:linear-gradient(#ffb4b6, 3%, white, white, white, white) !important;
        border-radius: 10px;
        margin-top: -15px;
      }

      /deep/ .el-dialog__headerbtn{
        top:10px;
        font-size: 24px;
      }
       /deep/.el-dialog__close{
        color:#fff !important;
      }

}
</style>
