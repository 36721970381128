const getters = {
    // permission
    // 所有允许使用的路由
    permission_routes: (state) => state.permission.routes,
    // 菜单路由
    menu_routes: (state) => state.permission.menuRoutes,
    //user
    userId: (state) => state.user.userId,
    token: (state) => state.user.token,
    openId: (state) => state.user.openId,
    id: (state) => state.user.id,
    cid: (state) => state.user.cid,
    avatar: (state) => state.user.avatar,
    uAccount: (state) => state.user.uAccount,
    cAccount: (state) => state.user.cAccount,
    accountCompany: (state) => state.user.accountCompany,
    accountUser: (state) => state.user.accountUser,
    username: (state) => state.user.username,
    userphone: (state) => state.user.userphone,
    loginState: (state) => state.user.loginState,
    loginId: (state) => state.user.loginId,
    // table
    tableList: (state) => state.table.tableList,
    shiptableList: (state) => state.table.shiptableList,
    shiptableListSH: (state) => state.table.shiptableListSH,
    queryList: (state) => state.table.queryList,
    queryList2: (state) => state.table.queryList2,
    queryList3: (state) => state.table.queryList3,
    manifestList: (state) => state.table.manifestList,
    sortMethods: (state) => state.table.sortMethods,
    pageSize: (state) => state.table.pageSize,
    pageSize2: (state) => state.table.pageSize2,
    liststate: (state) => state.table.liststate,
    totalTogetherNum: (state) => state.table.totalTogetherNum,
    // other
    webSocketMsg: (state) => state.other.webSocketMsg,
    systemKey: (state) => state.other.systemKey,
    // info
    recruitmentInfo: (state) => state.info.recruitmentInfo,
    preSheetList: (state) => state.info.preSheetList,
    // ship
    portclosetime: (state) => state.ship.portclosetime,
    goodsuuid: (state) => state.ship.goodsuuid,
    ctnapplyendtime: (state) => state.ship.ctnapplyendtime,
    searchShipData: (state) => state.ship.searchShipData,
    AisInfoData: (state) => state.ship.AisInfoData,
    shipTrackData: (state) => state.ship.shipTrackData,
    blonBoxInfo: (state) => state.ship.blonBoxInfo,
    shipInfo: (state) => state.ship.shipInfo,
    dockInfo: (state) => state.ship.dockInfo,
    allBoxInfo: (state) => state.ship.allBoxInfo,
    boxStatus: (state) => state.ship.boxStatus,
    shipStatus: (state) => state.ship.shipStatus,
};
export default getters;
