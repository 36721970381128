<template>
    <div
        class="button"
        :style="{
            marginTop: top + 'px',
        }"
        v-bind="$attrs"
    >
        <slot>
            <el-button
                type="info"
                plain
                @click="$emit('confirm')"
                class="confirmBtn"
                :class="showTab + '_confirmBtn'"
                v-if="confirmText"
                :loading="loading"
                v-bind="confirmOptions"
                :style="{
                    order: reverse ? 2 : 1,
                }"
            >
                {{ confirmText }}
            </el-button>
            <el-button
                type="info"
                plain
                @click="$emit('cancel')"
                class="cancelBtn"
                v-if="cancelText"
                :class="showTab + '_cancelBtn'"
                :disabled="loading"
                :style="{
                    order: reverse ? 1 : 2,
                }"
                v-bind="cancelOptions"
            >
                {{ cancelText }}
            </el-button>
        </slot>
    </div>
</template>
<script>
export default {
    props: {
        confirmText: {
            type: String,
            default: "确定",
        },
        cancelText: {
            type: String,
            default: "取消",
        },
        top: {
            type: String,
            default: "20",
        },
        reverse: {
            type: Boolean,
            default: false,
        },
        showTab: {
            type: String,
            default: "NB",
        },
        loading: {
            type: Boolean,
            default: false,
        },
        confirmOptions: {
            type: Object,
            default: () => ({}),
        },
        cancelOptions: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
<style scoped lang="stylus">
.button {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 30px;
    justify-content: center;
}

.confirmBtn {
    background-color: #de5d40 !important;
    color: white !important;
    border-radius: 7.5px;
}

.NB_confirmBtn {
    background-color: #de5d40 !important;
    border-color:#ffCCCC !important;
    color: white !important;
}

.SH_confirmBtn {
    background-color: #4286f4 !important;
    color: white !important;
    border-color:#CCFFFF !important;
}

.QD_confirmBtn {
    background-color: #6a9113 !important;
    color: white !important;
    border-color:#CCFFCC !important;
}

.TJ_confirmBtn {
    background-color:#f16529 !important;
    color: white !important;
    border-color:#FFFFCC !important;
}

.XM_confirmBtn {
    background-color: #6441a5 !important;
    color: white !important;
    border-color:#9966CC !important;
}

.cancelBtn {
    color: white !important;
    background: linear-gradient(to right, #e5917e, #daacb9) !important;
    border-radius: 7.5px;
}

.NB_cancelBtn {
    color: white !important;
    background: linear-gradient(to right, #e5917e, #daacb9) !important;
    border-color:#ffCCCC !important;
}

.SH_cancelBtn {
    color: white !important;
    background: linear-gradient(to right, #2980b9, #6dd5fa) !important;
    border-color:#CCFFFF !important;
}

.QD_cancelBtn{
    color: white !important;
    background: linear-gradient(to right, #11998e, #38ef7d) !important;
    border-color:#CCFFCC !important;
}

.TJ_cancelBtn{
    color: white !important;
    background: linear-gradient(to right, #f2994a, #f2c94c) !important;
    border-color:#FFFFCC !important;
}

.XM_cancelBtn{
    color: white !important;
    background: linear-gradient(to right, #5f2c82, #8e54e9) !important;
    border-color:#9966CC !important;
}
</style>
