<template>
    <NotesDialog
        @know="handleKnow"
        :visible.sync="visibleSync"
        width="700px"
        top="5vh"
    >
        <div class="center"><h1>迎新推广活动</h1></div>
        <div class="notice-content">
            <div class="line">致各位放舱网的用户：</div>
            <div class="line text">
                为感谢各位一直以来的支持，放舱网现推出迎新推广活动
                每邀请一位用户成功进行企业认证，我们将为您的账户充值
                <span class="bold">50火币</span>
                ，同时完成企业认证的 用户也会获得
                <span class="bold">30火币</span>
                （此活动开始之前已认证的用户，我们也将为您的账户充值30火币）
            </div>
            <div class="line bold">1.获取邀请码</div>
            <div class="line text">
                您可以在两个位置查看您的邀请码，首先是页面<span>右上角下拉菜单</span>，如图所示：
            </div>
            <div><img src="./imgs/note-invite/1.jpeg" /></div>
            <div class="line text"><span>用户中心-基本信息-我的邀请码</span>，如图所示：</div>
            <div><img src="./imgs/note-invite/2.jpeg" /></div>
            <div class="line bold">2.进行企业认证</div>
            <div><img src="./imgs/note-invite/3.jpeg" /></div>
            <div class="line bold">3.输入邀请码</div>
            <div><img src="./imgs/note-invite/4.jpeg" /></div>
            <div class="replenish-info">
                <div class="content">
                    <span>放舱网</span>
                    <span>2024年7月15日</span>
                </div>
            </div>
        </div>
    </NotesDialog>
</template>

<script>
import NotesDialog from "./NotesDialog.vue";
export default {
    components: {
        NotesDialog,
    },
    name: "NoteDialogInvite",
    data() {
        return {
            visibleSync: false,
        };
    },
    mounted() {
        const isKnowNoteOld1 = localStorage.getItem("isKnowNotes-1");
        const isKnowNoteOld2 = localStorage.getItem("isKnowNotes-2");
        const isKnowNotes = localStorage.getItem("isKnowNotes-invite");
        if (isKnowNotes !== "true" && isKnowNoteOld1 && isKnowNoteOld2) {
            this.visibleSync = true;
        }
    },
    methods: {
        handleKnow() {
            localStorage.setItem("isKnowNotes-invite", "true");
            this.visibleSync = false;
        },
        open() {
            this.visibleSync = true;
        },
    },
};
</script>

<style lang="stylus">
</style>
