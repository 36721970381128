// 导入文件
function importTxtFile(filePath, name, charsetType = "GBK") {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open(
            "GET",
            `https://web.nbhuojian.com/static/shendanxia_web/${filePath}`, // 要查看文件内容可查看public文件夹内对应文件
            false,
        );
        xhr.overrideMimeType(`text/plain; charset=${charsetType}`);
        xhr.send();

        if (xhr.readyState === 4 && xhr.status === 200) {
            const content = xhr.responseText;
            const lines = content.split("\n");
            const jsonData = [];

            for (let line of lines) {
                if (line.trim() === "") {
                    continue;
                }

                const [key, value] = line.split(":").map((item) => item.trim());
                jsonData.push({ label: value, value: key });
            }

            resolve([name, jsonData]);
        } else {
            reject();
        }
    });
}

const filePathList = [
    {
        path: "documentsAttachedList",
        name: "监管条件",
        keyName: "documentsAttachedList",
    },
];

export const importTxtDataToJSON = () => {
    return new Promise((resolve, reject) => {
        const promise = [];
        filePathList.forEach((item) => {
            promise.push(importTxtFile(`txt/${item.path}.txt`, item.keyName));
        });
        Promise.all(promise).then((value) => {
            const needSetValue = Object.fromEntries(value);
            resolve(needSetValue);
        });
    });
};

// hscode类树过滤没有注释的类目
export const getHscodeCategoryNoteIsEmpty = (id) => {
    const emptyIdEnum = {
        3453: true, // 第三类
        3543: true, // 第五类
        3880: true, // 第八类
        3910: true, // 第九类
        3941: true, // 第十类
        4158: true, // 第十二类
        4196: true, // 第十三类
        4263: true, // 十四类
        4624: true, // 十八类
        4681: true, // 十九类
        4690: true, // 二十类
        4733: true, // 二十一类
        4741: true, // 二十二类
        4742: true, // 九十八章
    };
    return emptyIdEnum[id];
};
