<template>
    <el-dialog
        title="余额不足提醒"
        :visible.sync="visibleSync"
        width="480px"
        append-to-body
        class="lack-money-dialog"
        :close-on-click-modal="false"
        center
    >
        <div style="display: flex; justify-content: center">
            <img :src="moneyImg" alt="" width="150" />
        </div>
        <div
            style="
                display: flex;
                justify-content: center;
                font-size: 18px;
                color: #c11c20;
            "
        >
            余额不足，请充值之后再进行操作
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button
                type="danger"
                plain
                class="cancelBtn"
                @click="handleConfirm"
            >
                前往充值
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    name: "lack-account-dialog",
    data() {
        return {
            visibleSync: false, // 是否显示弹窗
            moneyImg: require("../../assets/img/money/money.svg"),
        };
    },

    methods: {
        handleClose() {
            this.visibleSync = false;
        },
        handleConfirm() {
            this.visibleSync = false;
            window.$router.push("/pay/charge");
        },
        open() {
            // 显示
            this.visibleSync = true;
        },
        cancel() {
            // 关闭
            this.visibleSync = false;
        },
    },
};
</script>
<style scoped lang="stylus">
.lack-money-dialog{
    /deep/ .el-dialog{
        border-radius: 10px;
    }
    /deep/ .el-dialog__header{
        background:linear-gradient(to right, #de5d40, #e5917e, #daacb9)
        border-radius: 10px 10px 0 0;
    }
    /deep/ .el-dialog__body{
        padding:0;
        padding-bottom:20px;
    }
    /deep/ .el-dialog__title{
        color: rgb(154, 31, 18);
        font-weight: 530;
        font-size: 24px
    }
    /deep/ .el-dialog__headerbtn .el-dialog__close{
        font-size:20px;
        color:rgb(154, 31, 18);
    }
}

.cancelBtn {
    color: white !important;
    background: linear-gradient(to right, #e5917e, #daacb9) !important;
    border-radius: 15px;
    width:120px;
}
</style>
