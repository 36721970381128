<template>
  <el-dialog
    width="80%"
    top="30px"
    :visible.sync="dialogTableVisible"
    center
    append-to-body
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <el-card class="box-card">
      <div style="display: flex; flex-direction: row; justify-content: center">
        <p class="div_p" style="font-size: 20px">用户服务协议</p>
      </div>
      <div>
        <p class="div_p">服务协议的确认和接纳</p>
      </div>
      <div>
        &emsp;&emsp;放舱网网站（域名为www.fangcangwang.放舱网网站（域名为www.fangcangwang.com）由火燕软件科技（仙居）有限公司运营，涉及国际物流电商服务、物流运输代理相关业务以及信息提供咨询服务，具体产品服务由放舱网或具有资质的服务商提供。如果用户（“用户”或“您”）在本网站、放舱网关联公司网站或其他放舱网提供的移动应用或软件上（以下简称“放舱网”），访问、预定或使用我们的产品或服务（以上统称为“服务”），便视为用户接受了以下服务协议，请您仔细阅读以下内容。如果您不同意以下任何内容，请立刻停止访问本网站或使用本网站服务。您在完全同意协议内容并按要求完成注册程序和相关审核要求后，您将享受相对应的服务。通过访问或使用放舱网，即视为您同意遵守本协议内容，包括协议正文、附件及所有已经发布的或将来可能发布的各类规则，所有规则为本协议不可分割的部分，与协议内容具有同等的法律效力。在您登录放舱网并使用相关服务时，《服务协议》即时生效，在之后的服务使用过程中用户均应遵守本协议的约定。
      </div>
      <div>
        <p class="div_p">第一条 服务条款的修改</p>
      </div>
      <div>
        &emsp;&emsp;放舱网会在必要时修改服务条款，如制订、修改本协议及/或各类规则向用户提供基于互联网以及移动网的相关服务的，应在本页面及其相应页面提前公布通知，用户应该定期登陆本页面及其他相关页面，了解最新的协议内容。变更后的协议和规则在本页面及相关页面公布后七天，将自动生效。如您不同意相关变更，应当立即停止访问放舱网或使用放舱网服务，若用户在网站协议和规则变更七日后继续使用放舱网服务的，即表示您接受已经修订的协议和规则。此时由于用户不同意相关修订导致正在进行的交易终止所造成的双方损失，由用户承担。
      </div>
      <div>
        <p class="div_p">第二条 本站的用户信息及信息保密</p>
      </div>
      <div>
        <li>
          1、放舱网用户包括“个人用户”，和能够承担法律责任的企业法人用户（以下简称“企业用户”）。用户按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后成为个人用户。企业用户需要先通过放舱网的审核，
          企业用户在接受审核时，需填写正确真实的企业信息，并上传“营业执照”副本扫描件，经审核完成后成为放舱网注册企业用户。所有用户在使用平台服务期间，若有违反本协议相关规定，用户资格和账号可能被注销、暂时冻结或者永久冻结。账户注销后，相应的会员名将开放给任意用户注册登记使用。
        </li>
        <li>
          2、用户有义务维护自己在放舱网平台中的账户信息的完整性、密码的保密性。用户应不允许他人通过自己的账户使用放舱网平台，因用户自身维护或保密不善致使相关信息泄露，或由于第三方盗用用户帐号和密码进行各种操作而造成的损失，由用户自行负责。
        </li>
        <li>
          3、用户应当对其账户安全负责。如果用户发现任何人不当使用其账户或有任何其他可能危及其账户安全的情形时，应当立即以有效的方式通知放舱网并自行报警，放舱网为保证用户权益，有权在暂停相关服务前要求用户提供相关身份证明。放舱网对在采取行动前已经产生的后果，包括但不限于用户的任何损失，不承担任何责任。涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，放舱网将予以严格保密，除非得到用户的授权或法律另有规定，放舱网不会向外界披露用户隐私信息。
        </li>
      </div>
      <div>
        <p class="div_p">第三条 所有权及知识产权</p>
      </div>
      <div>
        <li>
          1、火燕软件科技（仙居）有限公司是本网站的制作者及拥有者,拥有此网站内容及资源的著作权等合法权利,受国家法律保护,有权不定时地对本协议及本站的内容进行修改，并在本站公开，无须另行通知用户。在法律允许的最大限度范围内，放舱网对本协议及本站内容拥有解释权。
        </li>
        <li>
          2、除法律另有强制性规定外，未经放舱网明确的书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本站的信息内容，否则，放舱网有权追究其法律责任。
        </li>
        <li>
          3、放舱网及其平台用户应保证其合法拥有履行本协议的一切必要权利（包括但不限于针对货物、信息和技术的相关知识产权、所有权、使用权、收益权等），且不侵犯对方及任何第三方的合法权利或权益；任何一方都应对其所有或经授权使用的权利之瑕疵导致的一切后果和损失独立承担全部责任。
        </li>
        <li>
          4、本站所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是放舱网或其内容提供者的财产，受中国和国际版权法的保护。本站上所有内容的汇编是放舱网的排他财产，受中国和国际版权法的保护。本站上所有软件都是放舱网或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。
        </li>
      </div>
      <div>
        <p class="div_p">第四条 责任限制及不可抗力</p>
      </div>
      <div>
        <li>
          1、除非另有明确的书面说明,本站及其所包含的或以其它方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在"按现状"和"按现有"的基础上提供的。除非另有明确的书面说明,放舱网不对本站的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。
        </li>
        <li>
          2、放舱网不保证网站是不受干扰的、没有故障的，不对因人为或非人为因素造成的用户与放舱网服务器之间的连接受阻而无法访问负责。放舱网用户需同意独立承担因互联网的特殊性而产生的包括黑客攻击、电脑病毒、未经授权的进入、互联网连通中断或者系统故障、电信部门技术调整导致的影响、政府管制而造成的暂时性关闭在内的任何影响网络正常运营的因素网站意外中断、操作或传输的迟延、引起的损失。
        </li>
        <li>
          3、因不可抗力（包括但不限于自然灾害、罢工或骚乱、暴动、战争行为、政府行为）等非放舱网原因，致使放舱网履行迟延或不能履行的，放舱网不承担任何责任。但放舱网应在不可抗力发生后及时通知用户并采取措施，尽最大努力减轻可能给用户造成的损失。
        </li>
        <li>
          4、用户不得将本协议项下的利益转让给与本协议无关的第三方，否则放舱网有权随时解除本协议或拒绝提供服务；服务终止的，用户仍应支付已经履行部分的费用，费用不足以弥补放舱网损失的，放舱网有权向用户进一步追偿。
        </li>
      </div>
      <div>
        <p class="div_p">第五条 用户依法言行义务</p>
      </div>
      <div>
        <li>
          1、用户在使用放舱网服务中的所有行为应遵守国家法律、法规、规章、规范性文件等规定及本协议的规定和要求，不违背社会公德，不损害国家或者任何第三人的任何合法权益。否则，产生的一切法律后果均由用户独立承担，并在此承诺若违反本项条款放舱网免于承担任何责任。
        </li>
        <li>
          2、本站保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。
        </li>
        <li>
          3、若用户未遵守以上规定的，本站有权作出独立判断并采取暂停或关闭用户帐号等措施。用户须对自己在网上的言论和行为承担法律责任。
        </li>
      </div>
      <div>
        <p class="div_p">第六条 用户责任与义务</p>
      </div>
      <div>
        <li>
          1、用户应清楚、明确地选择购买海运服务、运输服务、保险服务、国外段服务、物流信息提供等。用户应保证其在放舱网提交的任何有关订单的信息和资料都是真实和准确的。有特殊要求的，用户必须以书面形式（如“订舱备注”等）提出，由放舱网审核通过后实行。
        </li>
        <li>
          2、用户理解并同意，因服务相关内容填写不准确或不实所造成的一切责任或损失（包括但不限于货物订舱不成功、无法清关、海关罚款、信息显示有误等等），应由用户承担。在放舱网不知情的情况下，由于实际出运货物属于违反国家出口规定或者属于违法货物等而造成放舱网或此票业务相关的承运人及其它物流辅助服务商被国家机关处罚的情况，用户应承担因此处罚而导致的一切费用及其它不良后果。
        </li>
        <li>
          3、用户如需对订单或提单进行更改，应以书面形式通知放舱网（例如：登录网页修改订单数据、取消订单等），放舱网审核通过后实行；由订单更改所产生的一切费用、责任均由用户承担。若因客观原因正在进行的订单无法修改时，相关损失、责任由用户承担。
        </li>
        <li>
          4、用户应在系统推送信息指定的时间内提交相关资料（如报关资料等）、确认提单内容，以及提供各类申报信息，逾期造成的费用、责任由用户承担。
        </li>
        <li>
          5、用户理解并同意，查询车辆位置需要征得车主本人的同意，放舱网提醒用户查询前必须提前和车主沟通好查询事宜，以免出现侵权的情况；如用户未征得车主同意，查询了车辆位置，由此带来的法律责任将由用户自行承担。即使征得了车主同意，为避免侵权，放舱网敬告用户勿将车辆位置泄露给第三方。
        </li>
      </div>
      <div>
        <p class="div_p">第七条 费用结算与支付</p>
      </div>
      <div>
        <li>
          1、费用结算的具体约定以放舱网与客户所签定的实际业务合同内容执行，特殊产品服务放舱网接受预付款预订服务。
        </li>
        <li>
          2、放舱网支持线上电子支付及电汇方式支付。无论何种支付方式，企业用户以私对公帐户支付相关费用的客户，需要在完成支付前提交订单委托方授权的代付款委托书，并加盖订单委托方的公司公章以证明付款人与订单委托方的关系。
        </li>
        <li>
          3、若用户违反费用结算相关约定，放舱网有权单方面解除本协议，并有权不经另行通知单方扣押提单、或者其他相关单据，直至费用全部付清；由此产生的一切风险、责任、费用及对放舱网造成的损失，全部由用户承担。
        </li>
        <li>
          4、放舱网将根据用户的选择方式送达提相关业务文件、资料。若用户选择电子文件、资料可直接在放舱网网站下载；若用户选择纸质文件、资料，放舱网会通过委托快递将文件、资料寄送到用户指定地址；此种情况下用户应自行承担因快递原因所造成的一切损失。
        </li>
        <li>
          5、发票抬头默认为订单用户名称；用户负责确保发票开具信息的真实性和完整性，若放舱网有需要，用户应配合提供除注册时提供的材料之外的一些认证材料。由于客户信息填写错误而造成的发票修改费用或其它寄送问题，应由用户承担。
        </li>
        <li>
          6、无论中国境内或境外的用户通过平台委托放舱网进行服务，代理人均为火燕软件科技（仙居）有限公司。放舱网境外分公司或境外代理有权负责对中国境外用户出具相关发票，
          签发相关提单。中国境外用户应将相关运费付至放舱网境外分公司或境外代理帐户。
        </li>
      </div>
      <div>
        <p class="div_p">第八条 法律管辖和适用</p>
      </div>
      <div>
        &emsp;&emsp;本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区的有效法律（但不包括其冲突法规则）。
        如发生本协议与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。
        如缔约方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，任何一方均可向宁波鄞州区人民法院提起诉讼。
      </div>
      <div>
        <p class="div_p">第九条 其他</p>
      </div>
      <div>
        <li>
          1、放舱网网站所有者是指在政府部门依法许可或备案的放舱网网站经营主体。
        </li>
        <li>
          2、您在注册时选择"同意该协议"并完成注册即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。
        </li>
        <li>
          3、用户同意，放舱网有权将本合同项下的全部或者部分权利转让于其它关联公司，而仅有通知的义务，包括但不限于通过平台公告或电子邮件等方式通知用户。
        </li>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: center">
        <p class="div_p" style="font-size: 20px">放舱网免责说明</p>
      </div>
      <p class="p2">
        &emsp;&emsp;放舱网提醒您：在使用放舱网服务之前，请您务必仔细阅读并透彻理解本免责说明。您可以主动取消或停止使用放舱网提供的服务，但如果您使用放舱网服务，您的使用行为将被视为对本免责说明全部内容的认可。
      </p>
      <p class="p2">
        &emsp;&emsp;您完全理解并同意，鉴于放舱网以非人工检索方式、根据您键入的关键字或点击特定的产品或服务关键字自动生成的网页链接或相关的产品信息描述，例如物流价格、节点信息、车辆信息等，上述非人工检索方式，因缓存时间间隔或检索方式非完全智能等原因，有可能造成信息更新不及时或产品服务信息聚合、抽取不精准等瑕疵，您完全理解并豁免上述产品或服务瑕疵给您造成的不便，放舱网不承担任何责任。
      </p>
      <p class="p2">
        为方便您的使用，放舱网可能会对产品的可信赖程度而进行的评级、推荐或风险提示仅供您参考，放舱网不担保该等评级、推荐或风险提示的准确性和完整性，对推荐的网站的内容及服务亦不承担任何责任。
      </p>
      <p class="p2">
        &emsp;&emsp;您完全理解并同意，您通过放舱网购买的产品和服务，应按照相关网页中展示的说明、规定或政策等履行相关义务，享有相关权利，该等说明、规定或政策等与本说明共同构成您
        和放舱网的整体协议，您必须严格遵守。
      </p>
      <p class="p2">
        &emsp;&emsp;您了解并理解，您经由本平台发布的图形、图片或个人言论等，均表示了您个人的观点、观念和思想，并不代表放舱网的观点或主张，对于在享受网络服务的过程中可能会接触到令人不快、不适当等内容的，由您个人自行加以判断并承担所有风险，放舱网不承担任何责任。
        您了解并理解，本平台提供的三方信息，含船期、三方运价、三方公司企业资料等在内的信息不能作为您的判断依据。三方信息只能作为一个决策参考，放舱网只针对其中部分缴纳保证金的企业承担兼管风险，请您谨慎决定与第三方发生交易行为，由于私下交易造成的损失，放舱网不承担任何责任。
      </p>
      <p class="p2">
        &emsp;&emsp;您完全理解并同意，通过放舱网购买的产品和服务时，您在预定产品或服务后应当及时付款；您在预定产品或服务后未全额支付前，您尚未完成购买上述产品或服务。因上述产品或服务的价格、舱位、数量等实时更新或变化而造成您的额外费用、损失或无库存等，由您自行承担，放舱网不承担任何责任。您完全理解并同意，您在支付过程中，因网络中断、银行反馈信息错误等非放舱网的原因造成的付款失败，由您自行承担，放舱网不承担任何责任。
        请您及时保存或备份您的文字、图片等其他信息，您完全理解并同意，由于放舱网储存设备有限、设备故障、设备更新或设备受到攻击等设备原因或人为原因，您使用网路服务储存的信息或数据等全部或部分发生删除、毁损、灭失或无法恢复的风险，均由您自行承担，放舱网不承担任何责任。
      </p>
      <p class="p2">
        &emsp;&emsp;本免责声明要与中华人民共和国法律解析一致，包括法律条款中有争议抵触的内容，您和放舱网一致同意服从法院的管辖。若有任何服务条款与法律相抵触，那这些条款将按尽可能接近的方法重新解析，而其它条款则保持对用户产生法律效力和影响。因解释本协议以及用户通过放舱网预订任何产品或服务而导致的争议，将同意接受中华人民共和国大陆地区法院的管辖。
      </p>
      <div style="display: flex; flex-direction: row; justify-content: center">
        <p class="div_p" style="font-size: 20px">放舱网隐私政策</p>
      </div>
      <div>
        &emsp;&emsp;欢迎您访问放舱网！放舱网提醒您：在使用放舱网产品或服务前，请您务必仔细阅读并透彻理解本隐私政策全部条款。您可以主动取消或停止使用放舱网提供的服务，但如果您使用放舱网服务，您的使用行为将被视为对本声明全部内容的认可。
      </div>
      <div>
        <p class="div_p">第一条 隐私政策的确认和接纳</p>
      </div>
      <div>
        &emsp;&emsp;尊重用户个人隐私是火燕软件科技（仙居）有限公司的一项基本政策。放舱网和有资质的的供应商提供国际物流产品服务汇集于平台供用户查阅，同时帮助用户通过平台与放舱网或供应商联系并完成相关国际物流服务项目。用户通过完成注册程序并点击“完成注册”的按钮，表示用户明确知晓本隐私政策所列事实，并与火燕软件科技（仙居）有限公司达成协议并接受所有的服务条款。
        我们十分注重保护用户的个人信息及隐私安全，我们理解您通过网络向我们提供信息是建立在对我们信任的基础上，我们重视这种信任，也深知隐私对您的重要性，并尽最大努力全力保护您的隐私。
      </div>
      <div>
        <p class="div_p">第二条 信息收集</p>
      </div>
      <div>
        &emsp;&emsp;为了更好地为您提供服务，放舱网将遵循“合理、相关、必要”原则，且以您同意为前提收集信息，不会收集法律法规禁止收集的信息。
        火燕软件科技（仙居）有限公司不会公开、透露用户的个人资料及保存在放舱网会员服务中的非公开内容，除非有法律许可要求或火燕软件科技（仙居）有限公司在诚信的基础上认为透露这些信息在以下三种情况是必要的：
        我们十分注重保护用户的个人信息及隐私安全，我们理解您通过网络向我们提供信息是建立在对我们信任的基础上，我们重视这种信任，也深知隐私对您的重要性，并尽最大努力全力保护您的隐私。
      </div>
      <div>
        <li>
          ⑴ 遵守有关法律规定，遵火燕软件科技（仙居）有限公司合法服务程序。
        </li>
        <li>⑵ 保持维护放舱网的商标所有权。</li>
        <li>⑶ 在紧急情况下竭力维护用户个人和社会大众的隐私安全。</li>
        <li>⑷ 符合其他相关的要求。</li>
      </div>
      <div>
        <p class="div_p">第三条 特别授权</p>
      </div>
      <div>
        &emsp;&emsp;一般来说，我们将采取合理的措施保护您的个人信息，使其避免未经授权的披露。在遵守适用法律的前提下，我们可能会与下列实体（可能包括中国境内和中国境外实体）分享您的信息。您完全理解并不可撤销地、免费地授予放舱网及其关联公司、业务合作伙伴下列权利：
      </div>
      <div>
        <li>
          1.如果您是个人用户，您在使用放舱网产品的同时，将无条件授权并同意接受放舱网将您名下拥有或挂靠的车辆的信息（当前位置、历史轨迹和其他信息）向平台上其他用户开放。
        </li>
        <li>
          2.如果您认证成为企业用户，您在使用放舱网产品的同时，将无条件授权并同意接受放舱网将认证企业名下拥有或挂靠的车辆的信息（当前位置、历史轨迹和其他信息）向平台上其他用户开放。
        </li>
        <li>
          3.为确保交易安全，允许放舱网及其关联公司、业务合作伙伴对用户信息进行数据分析，并允许放舱网及其关联公司、业务合作伙伴对上述分析结果进行商业利用。
        </li>
        <li>
          4.放舱网会从关联公司、业务合作伙伴来源获得您的相关信息。例如当您通过我们关联公司、业务合作伙伴网站预订时，您向其提供的预订信息可能会转交给我们，以便我们处理您的订单、确保您顺利接受服务。
        </li>
        <li>
          5.您在享受放舱网提供的各项服务的同时，授权并同意接受放舱网向您的电子邮件、手机、通信地址等发送商业信息，包括不限于最新的放舱网产品信息、活动信息等。
        </li>
      </div>
      <div>
        <p class="div_p">第四条 法律</p>
      </div>
      <div>
        &emsp;&emsp;隐私政策要与该国的国家法律解析一致，包括法律条款中有争议抵触的内容。用户和火燕软件科技（仙居）有限公司一致同意服从法院的管辖。若有任何服务条款与法律相抵触，那这些条款将按尽可能接近的方法重新解析，而其它条款则保持对用户产生法律效力和影响，因解释本协议以及用户通过放舱网使用任何产品或服务而导致的争议，将同意接受中华人民共和国大陆地区法院的管辖。
      </div>
    </el-card>
  </el-dialog>
</template>

<script>

export default {
  data () {
    return {
      dialogTableVisible: false,
    }
  },

  computed: {},

  mounted () {
  },
  methods: {
    Init () {
      // 参数获取
      this.dialogTableVisible = true
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="stylus" scoped>
.div_p {
  font-weight: 700;
  font-size: 16px;
}

.p2 {
  margin-bottom: 8px;
  margin-top: 2px;
}

div {
  line-height: 22px;
}

.box-card {
  height: 80vh;
  overflow-y: auto; /* 开启滚动显示溢出内容 */
}
</style>
