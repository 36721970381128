import Vue from "vue";
// 引入组件
import LackFreeAccountDialog from "@/components/common/LackFreeAccountDialog.vue";

LackFreeAccountDialog.install = function (param) {
    // 创建构造器
    const LackAccountBox = Vue.extend(LackFreeAccountDialog);
    // 创建组件对象，并挂载
    let instance = new LackAccountBox() // $mount(document.createElement('div') 【也可以挂载到某个元素上】

    // 将组件添加到dom中
    const id = 'free-lack-account-dialog'
    const el = document.createElement('div')
    el.id = id
    document.body.appendChild(el);

    instance.$mount(`#${id}`);

    // 调用方法或操作属性
    Vue.nextTick(() => {
        instance.open(param);
    });
};

export default LackFreeAccountDialog;