export default [
    {
        path: '/advManage',
        name: 'advManage',
        component: () => import('@/views/AdvManage/AdvLayout'),
        meta: { roles: ['admin'] }
    },
    {
        path: '/advAddManage',
        name: 'advAddManage',
        component: () => import('@/views/AdvManage/UpdateAdvertisementDialog'),
        meta: { roles: ['admin'] }
    },
    {
        path: '/advUpdateManage',
        name: 'advUpdateManage',
        component: () => import('@/views/AdvManage/UpdateAdvertisementDialog'),
        meta: { roles: ['admin'] }
    },
    // 广告详情页
    {
        path: '/advDetail',
        name: 'AdvDetail',
        component: () => import("@/views/AdvFront/AdvertisementDetail"),
    },
]