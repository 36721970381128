/**
 * @description 校验外部链接
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
}

export function isEmail(val) {
    var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
    return reg.test(val);
}

export function isPhone(val) {
    //手机
    const phone = /^1[3465789]\d{9}$/;
    //固定电话
    const tel = /^0\d{2,3}-?\d{7,8}$/;
    return phone.test(val) || tel.test(val);
}
//判断输入内容只能是数字和字母，且字母自动转大写
export function setNumberFormat(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("^[0-9]*$"), //正则 大于0的数字；
            len = val.length,
            str = "";
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}
//判断输入内容只能是数字和字母，且字母自动转大写
export function setRightForrmat(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9]+"), //正则 英文+数字；
            len = val.length,
            str = "";
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}
//判断输入内容只能是数字和字母和加号，且字母自动转大写
export function setRightForrmatPlus(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9&.,+ /]+"), //正则 英文+数字；
            len = val.length,
            str = "";
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

//判断输入内容只能是数字和字母和空格,斜杠，且字母自动转大写
export function setRightForrmatSpace(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9 /]+"), //正则 英文+数字；
            len = val.length,
            str = "";
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

export function setRightForrmatSpace2(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9 -.]+"), //正则 英文+数字；
            len = val.length,
            str = "",
            codeReg2 = new RegExp("^(?![.]).*");
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i]) && codeReg2.test(val)) {
                if (val[i] == "-" && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "-" && val[i - 1] == "-") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == "-") {
                    str = str.substring(0, i - 1);
                }
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}
export function setRightHistoryForrmatSpace2(val) {
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9 -/.]+"), //正则 英文+数字；
            len = val.length,
            str = "",
            codeReg2 = new RegExp("^(?![.]).*");
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i]) && codeReg2.test(val)) {
                if (val[i] == "/" && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "/" && val[i - 1] == "/") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == "/") {
                    str = str.substring(0, i - 1);
                }
                str += val[i].toUpperCase();
            }
        }
        val = str;
        return val;
    }
}

export function checkRightFormat(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z-,]+"), //正则 英文+-；
            str = "";
        for (var i = 0; i < val.length; i++) {
            if (codeReg.test(val[i])) {
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

export function setRightForrmatSpace3(val) {
    let returnVal = "";
    if (val) {
        let codeReg = new RegExp("[A-Za-z0-9 -/.]+"), //正则 英文+数字；
            len = val.length,
            str = "",
            codeReg2 = new RegExp("^(?![.]).*");
        for (var i = 0; i < len; i++) {
            if (codeReg.test(val[i]) && codeReg2.test(val)) {
                if (val[i] == "/" && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "/" && val[i - 1] == "/") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == ".") {
                    str = str.substring(0, i - 1);
                }
                if (val[i] == "." && val[i - 1] == "/") {
                    str = str.substring(0, i - 1);
                }
                str += val[i].toUpperCase();
            }
        }
        returnVal = str;
    }
    return returnVal;
}

export function formatterTime(time) {
    if (time) {
        var year = time.substr(0, 4);
        var month = time.substr(4, 2);
        var day = time.substr(6, 2);
        var hour = time.substr(8, 2);
        var min = time.substr(10, 2);
        var second = time.substr(12, 2);
        return (
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hour +
            ":" +
            min +
            ":" +
            second
        );
    }
    return "-";
}

export function getDate(time) {
    if (time) {
        return time.substr(0, 10);
    }
    return "-";
}

export function getDate2(time) {
    if (time) {
        var year = time.substr(0, 4);
        var month = time.substr(4, 2);
        var day = time.substr(6, 2);
        return year + "-" + month + "-" + day;
    }
    return "-";
}

export function getTime(time) {
    if (time) {
        return time.substr(11, 8);
    }
    return "";
}

export function getTime2(time) {
    if (time) {
        var hour = time.substr(8, 2);
        var min = time.substr(10, 2);
        var second = time.substr(12, 2);
        return hour + ":" + min + ":" + second;
    }
    return "";
}

export function getMatou(name) {
    switch (name) {
        case "ALTCNB":
            return "安联储运";
        case "B2SCT":
            return "北仑山码头";
        case "BDNLPG":
            return "百地年";
        case "BLCT":
            return "二期";
        case "BLCT2":
            return "三期";
        case "BLCT3":
            return "四期";
        case "BLCTCFS":
            return "通达公司";
        case "BLCTMS":
            return "梅山码头";
        case "BLCTMSCFS":
            return "四海物流";
        case "BLCTYD":
            return "远东(五期)";
        case "BLCTZS":
            return "大榭招商";
        case "BLHH2":
            return "北二司";
        case "CSCFS":
            return "长胜堆场";
        case "DXCTE":
            return "大榭信业码头";
        case "JIAYANG":
            return "佳洋物流";
        case "LONGZHOU":
            return "龙洲物流";
        case "NBAD":
            return "安达堆场";
        case "NBDNL":
            return "东南物流";
        case "NBNX":
            return "宁翔储运";
        case "NBRY":
            return "瑞源堆场";
        case "NBZR":
            return "宁波中燃";
        case "NDCC":
            return "大港堆场";
        case "NHCC":
            return "和欣货柜";
        case "NSSL":
            return "新世洋物流";
        case "SXZG":
            return "三星重工";
        case "WHMT":
            return "万华码头";
        case "WZCT":
            return "温州港区";
        case "XUNDA":
            return "迅达仓储";
        case "YGHAL":
            return "永港海安";
        case "YZCT":
            return "甬舟码头";
        case "ZHCT":
            return "镇司(ZHCT)";
        case "ZIT":
            return "乍浦码头";
        case "ZRCCJHC":
            return "中石化镇海";
        case "ZHGC":
            return "镇司(ZHGC)";
        case "CNDTU":
            return "状元岙码头";
        case "CNDMY":
            return "大麦屿码头";
        case "NCICL":
            return "大港新世纪";
        default:
            return name || "";
    }
}
//码放状态转成中文
export function passFlag2String(status) {
    const type = {
        Y: "已放行",
        N: "不能放行",
        T: "取消放行",
    };
    return type[status] || "未放行";
}

// 海关查验状态(不展开)
export function checkCusmovIsCheck(value) {
    const type = {
        Y: "986(机检)",
        N: "人工",
        1: "人工",
        2: "机检",
        3: "人工+机检",
        4: "机检转人工",
        5: "机检转人工（人工查验）",
        6: "前置卫生处理",
    };
    return type[value] || value || "";
}

// 历史记录删除原因
export function checkDeletedReason(value) {
    const type = {
        1: "主动删除",
        2: "离港后1个月",
        3: "添加订阅后1个月内无任何数据更新",
        4: "多方原因未完结60天",
    };
    return type[value] || "-";
}
