<!-- 此为个人信息页忘记密码弹窗 -->
<template>
    <NormalDialog
        :visible.sync="dialogTableVisible"
        :show-close="true"
        class="three-step"
        :height="40"
        width="500px"
        center
        append-to-body
    >
        <template #title>
            <img :src="forgetImg" alt="" width="140" class="forget-img" />
            <div class="forget-title">忘记密码</div>
        </template>

        <template #content>
            <div class="body">
                <!-- 登录表单区域  title="请绑定个人信息"-->
                <span class="font_step">简单三步，立即完成</span>
                <el-steps
                    :active="stepIndex"
                    class="step"
                    simple
                    finish-status="success"
                    align-center
                >
                    <el-step title="验证身份" icon="el-icon-s-check"></el-step>
                    <el-step
                        title="填写新密码"
                        icon="el-icon-edit-outline"
                    ></el-step>
                    <el-step
                        title="修改完成"
                        icon="el-icon-document-checked"
                    ></el-step>
                </el-steps>

                <!-- 手机号 -->
                <el-form
                    ref="loginFormRef1"
                    :inline="false"
                    :model="loginForm"
                    label-position="top"
                    label-width="90px"
                    :rules="loginFormRules"
                    v-if="stepIndex == 0"
                >
                    <el-form-item label="手机号" prop="userTel">
                        <el-input v-model="loginForm.userTel" :disabled="disabled">
                            <el-select
                                v-model="value"
                                slot="prepend"
                                :disabled="true"
                                placeholder="+86"
                                class="select"
                            >
                                <el-option
                                    v-for="item in options"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="短信验证码" prop="noteCode">
                        <phone-code
                            :phone="loginForm.userTel"
                            v-model="loginForm.noteCode"
                            @verifyPhone="verifyLoginForm1"
                        ></phone-code>
                    </el-form-item>
                </el-form>
                <el-form
                    ref="loginFormRef2"
                    :model="loginForm2"
                    label-position="top"
                    label-width="90px"
                    :rules="loginFormRules2"
                    v-if="stepIndex == 1"
                >
                    <div class="pos_2">
                        <el-form-item
                            label="新密码"
                            prop="password"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="loginForm2.password"
                                type="password"
                                show-password
                                auto-complete="new-password"
                            ></el-input>
                        </el-form-item>
                        <el-form-item
                            label="确认密码"
                            prop="password2"
                            style="width: 100%"
                        >
                            <el-input
                                v-model="loginForm2.password2"
                                type="password"
                                show-password
                                auto-complete="new-password"
                            ></el-input>
                        </el-form-item>
                    </div>
                </el-form>
                <div v-if="stepIndex == 2">
                    <el-row type="flex" justify="center">
                        <div class="content-mess">
                            <p style="font-size: 24px">修改成功</p>
                            <img :src="imageSuccess" alt="" width="200" />

                            <p style="font-size: 20px">密码修改成功</p>
                        </div>
                    </el-row>
                </div>
                <el-row type="flex" justify="end">
                    <el-button
                        type="danger"
                        plain
                        :disabled="isCode"
                        v-if="stepIndex == 0"
                        style="margin-bottom: 10px"
                        @click="nextStep"
                        class="next-btn"
                    >
                        下一步
                    </el-button>
                    <el-button
                        type="danger"
                        plain
                        v-if="stepIndex == 1"
                        style="margin-bottom: 10px"
                        @click="success"
                        class="next-btn"
                    >
                        完成
                    </el-button>
                </el-row>
                <el-row
                    v-if="stepIndex == 2"
                    slot="footer"
                    type="flex"
                    justify="end"
                    style="width: 100%"
                >
                    <el-button
                        type="danger"
                        v-if="stepIndex == 2"
                        plain
                        class="next-btn"
                        @click="dialogTableVisible = false"
                    >
                        完成
                    </el-button>
                </el-row>
            </div>
        </template>
    </NormalDialog>
</template>

<script>
import PhoneCode from "@/components/common/PhoneCode";
import NormalDialog from "@/components/layout/NormalDialog";
import md5 from "js-md5";
import { apiCallback } from "@/utils";
import { userForgetPwd } from "@/api";
import { checkCodes } from "@/api/login";
import { phoneReg } from "@/utils/config";

export default {
    name: "forgetPwdDialog",
    props:{
        disabled:{
            type:Boolean,
            default:false
        }
    },
    data() {
        var validatePass = (rule, value, callback) => {
            var passwordreg = new RegExp(
                "(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}",
            );
            var isValid = passwordreg.test(value);
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value.length > 16 || value.length < 8) {
                callback(new Error("密码必须8~16位之间"));
            } else if (isValid != true) {
                callback(new Error("密码必须包含大小写字母和数字"));
            } else {
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.loginForm2.password) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            forgetImg: require("@/assets/img/login/forget.png"),
            imageSuccess: require("@/assets/img/company/success.png"),
            isregister: "",
            msg: "",
            id: 0,
            identifyCodes: "1234567890",
            identifyCode: "",
            dialogTableVisible: false,
            isCode: false,
            stepIndex: 0,
            options: [
                {
                    value: "HTML",
                    label: "HTML",
                },
                {
                    value: "CSS",
                    label: "CSS",
                },
                {
                    value: "JavaScript",
                    label: "JavaScript",
                },
            ],
            value: [],
            // 这是登录表单的数据绑定对象
            loginForm: {
                userTel: "",
                noteCode: "",
            },
            loginForm2: {
                password: "",
                password2: "",
            },
            // 这是表单的验证规则对象
            loginFormRules: {
                userTel: [
                    { message: "请输入手机号码", trigger: "blur" },
                    {
                        min: 11,
                        max: 11,
                        message: "请输入11位手机号码",
                        trigger: "blur",
                    },
                    {
                        pattern: phoneReg,
                        message: "请输入正确的手机号码",
                    },
                ],
            },
            loginFormRules2: {
                // 验证密码是否合法
                password: [
                    {
                        required: true,
                        validator: validatePass,
                        trigger: "blur",
                    },
                ],
                password2: [
                    {
                        required: true,
                        validator: validatePass2,
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    components: {
        PhoneCode,
        NormalDialog,
    },
    mounted() {},
    methods: {
        init(userTel, id) {
            this.dialogTableVisible = true;
            this.stepIndex = 0;
            this.loginForm.userTel = userTel;
            this.id = id;
        },
        // 点击重置按钮，重置登录表单
        resetLoginForm() {
            if (this.$refs.loginFormRef1) {
                this.$refs.loginFormRef1.resetFields();
            }
            if (this.$refs.loginFormRef2) {
                this.$refs.loginFormRef2.resetFields();
            }
        },
        nextStep() {
            this.$refs.loginFormRef1.validate(async (valid) => {
                if (valid) {
                    if (this.loginForm.noteCode) {
                        this.checkCode()
                            .then(() => {
                                this.stepIndex = 1;
                                this.loginForm.noteCode = "";
                            })
                            .catch((err) => console.error(err));
                    }
                }
            });
        },
        success() {
            this.$refs.loginFormRef2.validate(async (valid) => {
                if (valid) {
                    var that = this;
                    userForgetPwd({
                        phone: this.loginForm.userTel,
                        pwd: md5(this.loginForm2.password),
                    })
                        .then(async (res) => {
                            try {
                                await apiCallback(res);
                                that.$message.success("修改成功");
                                that.stepIndex = 2;
                                that.resetLoginForm();
                            } catch (err) {
                                console.error(err);
                            }
                        })
                        .catch((err) => console.log(err));
                }
            });
        },
        async checkCode() {
            const params = new URLSearchParams();
            params.append("phone", this.loginForm.userTel);
            params.append("code", this.loginForm.noteCode);
            return await checkCodes(params);
            // return await this.$axios2.post('/textmessage/checkCode', params)
        },
        verifyLoginForm1() {
            this.$refs.loginFormRef1.validate();
        },
    },
};
</script>

<style lang="stylus" scoped>
.forget-img {
    position: absolute;
    top:-82px;
    left:-20px
}
/deep/ .el-steps{
    margin:30px 0;
}

.select {
    width: 90px;
  }

  .forget-title{
    font-size: 24px;
    font-weight: bold;
    color:white;
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
  }
</style>
