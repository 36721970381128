import md5 from "js-md5";
import qs from "qs";
import { Message, MessageBox } from "element-ui";
import { router } from "../router";
import { getAccount } from "../api/pay";
import { getCompanyId, getRole } from "./auth";
import store from "../store";
import { verFreeBalanceEn } from "@/api/tracking";
import LackFreeAccountDialog from "./register-lack-free-accout-dialog";
import { payRate } from "@/utils/pay-data-list";
import { qdWharfList } from "@/api/qingdaoShipQuery";
// file文件转base64
export const fileByBase64 = (file, callback) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
        console.log(e.target.result);
        callback(e.target.result);
    };
};

//防抖函数
export const debounce = (fn, delay) => {
    let timer = null;
    return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            fn();
        }, delay);
    };
};

/**
 * @description 计算文字长度
 * @param {String} text 文字内容
 * @param {Number} fontSize 文字尺寸大小
 * @returns 
 */
export function getTextWidth(text, fontSize) {
    // 创建临时元素
    const _span = document.createElement("span");
    // 放入文本
    _span.innerText = text?.replaceAll(/\s/g, "1") || "";
    // 设置文字大小
    _span.style.fontSize = fontSize + "px";
    // span元素转块级
    _span.style.position = "absolute";
    // span放入body中
    document.body.appendChild(_span);
    // 获取span的宽度
    let width = _span.offsetWidth;
    // 从body中删除该span
    document.body.removeChild(_span);
    // 返回span宽度
    return width;
  }

// 检查登录用户角色
export const checkRole = (type) => {
    var localtype = md5("role");
    const storeMenus = localStorage.getItem(localtype);
    if (storeMenus) {
        var vuex = localStorage.getItem("vuex");
        var id = "";
        if (vuex) {
            var data = JSON.parse(vuex);
            if (data.user) {
                id = data.user["userId"];
            }
        }
        var role = md5(type + "/" + id);
        return role == storeMenus;
    }
    return false;
};

// 获取url参数
export const getUrlParams = (url) => {
    const curUrl = url || window.location.href;
    const paramStr = curUrl.split("?")[1];
    const paramObj = qs.parse(paramStr);
    return paramObj || {};
};

/**
 * 调用接口后的处理函数
 * @param {Object} response 接口返回的数据
 * @param {Boolean} needStructure 是否需要把data数据结构出来
 * @param {Boolean} showError 是否需要展示错误
 *  */
export const apiCallback = (response, needStructure = true) => {
    const { data } = response.data;
    if (needStructure) {
        return data;
    } else {
        return response.data;
    }
};

/**
 * 过滤对象中空字段
 */
export const objectFilterEmpty = (obj) => {
    if (obj instanceof Object) {
        let result = {};
        Object.keys(obj).forEach((key) => {
            const curVal = obj[key];
            if (curVal !== "" && curVal !== undefined && curVal !== null) {
                result[key] = curVal;
            }
        });
        return result;
    }
    return obj;
};

// 拆分 日期 和 时间
export function splitDateAndTime(timeStr) {
    if (timeStr) {
        const [date, time] = timeStr.split(" ");
        return {
            date,
            time,
        };
    }
    return {
        date: "",
        time: "",
    };
}

// 在数组中间插入某个值
export function insertValueBetweenElements(arr, value) {
    const result = [];
    for (let i = 0; i < arr.length - 1; i++) {
        result.push(arr[i], value);
    }
    result.push(arr[arr.length - 1]);
    return result;
}

//将数据中的字符串转为json
export function getString2Json(data) {
    var json = eval("[" + data + "]")[0];
    return json;
}
export function getString2JsonArr(data) {
    var json = eval(data);
    return json;
}

/**
 * 获取海关查验状态
 */
export const getCheckStatusStr = (status) => {
    let resultStr = "";
    if (status === "C" || status === "1") {
        resultStr = "查验";
    } else if (status === "CM" || status === "2") {
        resultStr = "能够移箱";
    } else if (status === "OK" || status === "3") {
        resultStr = "移箱完成";
    } else if (status === "OT" || status === "4") {
        resultStr = "完成归位";
    } else if (status === "UM" || status === "5") {
        resultStr = "不能移箱";
    } else if (status === "R" || status === "6") {
        resultStr = "撤销";
    } else if (status === "CR" || status === "7") {
        resultStr = "能够撤销";
    } else if (status === "OR" || status === "8") {
        resultStr = "撤销完成";
    } else if (status === "UR" || status === "9") {
        resultStr = "不能撤销";
    } else if (status === "P" || status === "10") {
        resultStr = "拼箱";
    } else if (status === "O" || status === "11") {
        resultStr = "移箱指令收到";
    } else if (status === "E" || status === "12") {
        resultStr = "移箱操作完成";
    } else if (status === "13") {
        resultStr = "N";
    } else if (status === "14") {
        resultStr = "Y";
    } else if (status === "Z" || status === "15") {
        resultStr = "已进入作业环节，请人工处理";
    } else if (status === "G" || status === "16") {
        resultStr = "归位完成";
    } else if (status === null || status === "N") {
        resultStr = "未查验";
    }
    return resultStr;
};

/**
 * 获取986查验字符串
 */
export const getCheck986Str = (isCheck986) => {
    let resultStr = isCheck986;
    if (isCheck986 === "Y") {
        resultStr = "986(机检)"; //986查验
    } else if (isCheck986 === "N") {
        resultStr = "人工"; //986查验
    } else if (isCheck986 === "1") {
        resultStr = "人工"; //986查验
    } else if (isCheck986 === "2") {
        resultStr = "机检"; //986查验
    } else if (isCheck986 === "3") {
        resultStr = "人工+机检"; //986查验
    } else if (isCheck986 === "4") {
        resultStr = "机检转人工"; //986查验
    } else if (isCheck986 === "5") {
        resultStr = "机检转人工（人工查验）"; //986查验
    } else if (isCheck986 === "6") {
        resultStr = "前置卫生处理"; //986查验
    }
    return resultStr || "";
};

/**
 * 码头代码 + 中文
 */
export const wharfCodeToStr = (str) => {
    const type = {
        BLCT: "二期",
        BLCT2: "三期",
        BLCT3: "四期",
        BLCTYD: "远东",
        BLCTZS: "大榭招商",
        BLCTMS: "梅山",
        ZHCT: "镇司",
        B2SCT: "北仑山码头",
        ZIT: "乍浦码头",
        YZCT: "甬舟码头",
    };
    return type[str] || str || "";
};

/**
 * 码放状态转中文
 */
export const passFlag2String = (status, isShowNot = false) => {
    if (status === "Y") {
        return "已放行";
    } else if (status === "N" && !isShowNot) {
        return "不能放行";
    } else if (status === "T") {
        return "取消放行";
    } else {
        return "未放行";
    }
};

/**厦门海放码放数字转中文 */
export const passFlag2StringXM = (status) => {
    if (status == "1") {
        return "已放行";
    } else if (status == "0") {
        return "未放行";
    } else if(status == "2"){
        return "取消放行";
    }else{
        return  ""
    }
};

//中国标准时间转化(最好用moment.js)
export function msToDate(msec) {
    let datetime = new Date(msec);
    let year = datetime.getFullYear();
    let month = datetime.getMonth();
    let date = datetime.getDate();
    let hour = datetime.getHours();
    let minute = datetime.getMinutes();
    let second = datetime.getSeconds();
    let result1 =
        year +
        "-" +
        (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (date + 1 <= 10 ? "0" + date : date) +
        " " +
        (hour + 1 <= 10 ? "0" + hour : hour) +
        ":" +
        (minute + 1 <= 10 ? "0" + minute : minute) +
        ":" +
        (second + 1 <= 10 ? "0" + second : second);

    let result2 =
        year +
        "-" +
        (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (date + 1 <= 10 ? "0" + date : date);

    let result = {
        hasTime: result1,
        withoutTime: result2,
    };
    return result;
}

// 不打开新窗口下载文件
export function downLoadFile(url) {
    const aLink = document.createElement("a");
    aLink.style.display = "none";
    aLink.href = url;
    aLink.click();
}
function isBillNo(str) {
    // 判断是否是提单号
    return str.length > 8 && str.match(/[\dA-Z]{3,}.*?\d+[A-Z]{0,2}/) != null;
}

function splitVoyage(shipStr) {
    // 判断船名航次是否符合标准
    let splitStr;
    if (shipStr.indexOf("/") != -1) {
        splitStr = "/";
    } else if (shipStr.indexOf("v.") != -1) {
        splitStr = "v.";
    } else if (shipStr.indexOf("V.") != -1) {
        splitStr = "V.";
    }
    return {
        trafName: shipStr.split(splitStr)[0],
        voyage: shipStr.split(splitStr)[1],
    };
}

function checkHasSplitStr(str) {
    // 判断是否有分隔符
    return (
        str.indexOf("/") != -1 ||
        str.indexOf("v.") != -1 ||
        str.indexOf("V.") != -1
    );
}

// 获取固定格式字符串中的船名航次和提单号数据
export function getVoyageAndBillNo(str) {
    let newStr = str.trim().replace("\r\n", "\t");
    newStr = newStr.replace("\n", "\t");
    newStr = newStr.replace("\r", "\t");
    let arr = newStr.split(/\s+/);
    let billNo, voyageObj;
    let shipStr;
    // 提单号必须是第一个或最后一个元素
    if (isBillNo(arr[0])) {
        // 船名航次不是是空格分隔的且有提单号
        billNo = arr[0];
        arr.shift();
        shipStr = arr.join(" ");
        voyageObj = splitVoyage(shipStr);
    } else if (isBillNo(arr[arr.length - 1])) {
        // 船名航次不是是空格分隔的且有提单号
        billNo = arr[arr.length - 1];
        arr.pop();
        shipStr = arr.join(" ");
        voyageObj = splitVoyage(shipStr);
    } else if (checkHasSplitStr(newStr)) {
        // 只有船名航次且不为空格分隔
        voyageObj = splitVoyage(arr.join(" "));
    } else {
        // 只有船名航次且为空格分隔
        const curVoyage = arr[arr.length - 1];
        arr.pop();
        voyageObj = {
            trafName: arr.join(" "),
            voyage: curVoyage,
        };
    }

    return {
        billNo: billNo,
        ...voyageObj,
    };
}

// 获取剪切板内容
export function getClipboardData() {
    return new Promise((resolve, reject) => {
        if (navigator.clipboard) {
            navigator.clipboard
                .readText()
                .then((text) => {
                    resolve(text);
                })
                .catch((err) => {
                    reject(err);
                });
        } else {
            reject();
        }
    });
}
// 校验当前路由是否可以跳过登录这一步骤
export const checkCurUrlCanSkipLogin = () => {
    let returnVal = false;
    const { path, query } = router.currentRoute;
    // 测试数据:?referer=yld&vessel=MSC LENI&voyage=FJ248W&sign=930d2a655ef416b220c96edf2dee1086
    // 1. path -boxquery 2.query referer=yld&vessel=船名&voyage=航次&sign=md5(referer+船名+航次)
    // console.log(path, router.currentRoute, query, query.referer || '', query.vessel || '', query.voyage || '', query.sign || '')
    // console.log(Boolean(path === '/boxquery' && query.referer && query.referer === 'yld' && query.vessel && query.voyage && query.sign), md5(query.referer || '' + query.vessel || '' + query.voyage || ''))
    if (
        Boolean(
            path === "/boxquery" && query.vessel && query.voyage && query.sign,
        )
    ) {
        // console.log(md5(query.referer || '' + query.vessel || '' + query.voyage || ''), query.sign)
        if (
            query.sign === md5("yld" + query.vessel || "" + query.voyage || "")
        ) {
            returnVal = true;
        }
    }
    return returnVal;
};

/**
 * @description 校验免费余额是否足够
 * @param {String} showTab  港口类型,NB,SH,QD,TJ,XM
 * @param {Void} fn  额度足够执行的方法
 * @param {String} type FEE_NB_VESSEL：宁波船期 FEE_VESSEL：上海船期 FEE_BLNO：提单订阅(根据showTab判断宁波还是上海订阅)
 * @param {Void} errorFun  额度不足执行的方法
 * @param {*} cancelFun 点击取消执行的方法
 * @returns 
 */
export const freeBalanceLackTip = (showTab, fn, type, errorFun, cancelFun) => {
    const verFreeBalanceParam = {
        companyId: getCompanyId(),
        origin: showTab,
        role: getRole(),
        payAccountMoneyEnum:
            type ??
            (showTab === "NB"
                ? "FEE_NB_VESSEL"
                : showTab === "SH"
                ? "FEE_VESSEL"
                : ""),
    };
    if (!verFreeBalanceParam.payAccountMoneyEnum) {
        fn && fn();
        return;
    }
    const localCurDay = localStorage.getItem("isShowFreeBalanceTips");
    let parseJson = {};
    try {
        parseJson = JSON.parse(localCurDay);
    } catch {
        parseJson = {};
    }

    const finishOrder = () => {
        const curDay = new Date().getMonth() + 1 + "-" + new Date().getDate();
        // 如果是当天, 则不再提示
        if (
            localCurDay &&
            parseJson[showTab] &&
            parseJson[showTab] === curDay
        ) {
            fn && fn();
            return;
        }
        // FEE_NB_VESSEL FEE_VESSEL
        return verFreeBalanceEn(verFreeBalanceParam)
            .then(() => {
                fn && fn();
            })
            .catch((err) => {
                // 有错误的方法执行错误方法
                errorFun && errorFun();
                let msg = "";
                if (
                    showTab === "SH" &&
                    verFreeBalanceParam.payAccountMoneyEnum === "FEE_BLNO"
                ) {
                    msg = "上海提单订阅即将扣除真实火币余额，是否继续？";
                } else if (
                    showTab === "NB" &&
                    verFreeBalanceParam.payAccountMoneyEnum === "FEE_BLNO"
                ) {
                    msg = "宁波提单订阅即将扣除真实火币余额，是否继续？";
                } else if (
                    verFreeBalanceParam.payAccountMoneyEnum === "FEE_NB_VESSEL"
                ) {
                    msg = "宁波船期订阅即将扣除真实火币余额，是否继续？";
                }
                LackFreeAccountDialog.install({
                    func: fn,
                    showTab,
                    msg,
                    parseJson,
                });
                // const tips = `${showTab === "NB" ? "宁波" : "上海"
                //     }免费额度不足, 继续将扣除真实余额，是否继续?`;
                // MessageBox.confirm(tips, "提示", {
                //     confirmButtonText: "继续且今日不再提示",
                //     cancelButtonText: "取消",
                //     type: "warning",
                // }).then(() => {
                //     const parseObj = {
                //         ...parseJson,
                //         [showTab]: curDay
                //     }
                //     // 清除其他的
                //     Object.keys(parseObj).forEach(key => {
                //         // console.log(key)
                //         if (key !== 'NB' && key !== 'SH') {
                //             delete parseObj[key]
                //         }
                //     })
                //     // console.log(parseObj, 'parseObj')
                //     const parseStr = JSON.stringify(parseObj)
                //     localStorage.setItem("isShowFreeBalanceTips", parseStr);
                //     fn && fn()
                // });
            });
    };

    // if (msg) {
    //     MessageBox.confirm(msg, "提示", {
    //         type: "warning",
    //     }).then(() => {
    //         finishOrder()
    //     }).catch(() => {
    //         cancelFun && cancelFun()
    //     })
    // }
    // else {
    return finishOrder();
    // }
};

// 获取余额
export function getUserAccountInfo(needShowCal = false) {
     // 是否是企业账户
     const isNotCompany =
     !store.state.user.loginId ||
     store.state.user.loginId === "personal";
    const param={};
    if(!isNotCompany){
        param.companyId = getCompanyId();
    }
    getAccount(param)
        .then(({ data }) => {
            // 如果需要展示消耗的额度
            if (needShowCal) {
                const oldAccount = store.state.user.accountUser;
                const oldCompany = store.state.user.accountCompany;
                const newAccount = data.data?.accountUser || {};
                const newCompany = data.data?.accountCompany || {};
                let oldAccountData = isNotCompany ? oldAccount : oldCompany;
                let newAccountData = isNotCompany ? newAccount : newCompany;
                if (newAccountData && oldAccountData) {
                    // balance 余额 freeBalance 可用上海免费余额 nbFreeBalance 可用宁波免费余额
                    // 余额差
                    const diffBalance =
                        (Number(oldAccountData.balance || 0) -
                            Number(newAccountData.balance || 0)) /
                        payRate;
                    // 可用上海免费余额差
                    const diffFreeBalance =
                        (Number(oldAccountData.freeBalance || 0) -
                            Number(newAccountData.freeBalance || 0)) /
                        payRate;
                    // 可用宁波免费余额差
                    const diffNbFreeBalance =
                        (Number(oldAccountData.nbFreeBalance || 0) -
                            Number(newAccountData.nbFreeBalance || 0)) /
                        payRate;
                    let str = "本次订阅消耗:";
                    if (diffBalance) {
                        str += `真实额度${diffBalance}火币`;
                    }
                    if (diffFreeBalance) {
                        str += `上海免费额度${diffFreeBalance}火币`;
                    }
                    if (diffNbFreeBalance) {
                        str += `宁波免费额度${diffNbFreeBalance}火币`;
                    }
                    // 如果是消耗真实额度或者消耗上海免费额度,做个提示
                    if (diffBalance || diffFreeBalance) {
                        Message({
                            type: "warning",
                            message: str,
                            duration: 6000,
                        });
                    }
                }
            }
            if (isNotCompany) {
                store.commit(
                    "user/editAccountUser",
                    data.data?.accountUser || {},
                );
            } else {
                store.commit(
                    "user/editAccountCompany",
                    data.data?.accountCompany || {},
                );
            }
        })
        .catch((err) => {
            MessageBox.alert("获取用户火币账户信息失败", "提示");
        });
}

// 得到青岛码头数据
export function getWarfList() {
    // qdWharfList
    return new Promise((resolve, reject) => {
        const list = store.state.ship.qdWharfList;
        if (list && list.length > 0) {
            resolve(list);
        } else {
            qdWharfList()
                .then((res) => {
                    // console.log(res,'res')
                    if (res.data && res.data.data && res.data.data.length > 0) {
                        store.commit("ship/editQdWharfList", res.data.data);
                        resolve(res.data.data);
                    } else {
                        store.commit("ship/editQdWharfList", []);
                        resolve([]);
                    }
                })
                .catch(() => {
                    store.commit("ship/editQdWharfList", []);
                    resolve([]);
                });
        }
    });
}

// 得到对应的CODE的名称
export function getNameByCode(code) {
    const namePlaceEnum = {
        SH: "上海",
        NB: "宁波",
        QD: "青岛",
        TJ: "天津",
        XM:"厦门"
    };
    return namePlaceEnum[code] || "宁波";
}

export function fileStreamDownload(data, fileName) {
    let blob = new Blob([data], {
        type: "application/vnd.ms-excel;charset=utf-8",
    }); // res就是接口返回的文件流了
    let url = window.URL || window.webkitURL;
    let fileURL = url.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = fileURL;
    a.download = fileName;
    a.target = "_self";
    a.click();
    url.revokeObjectURL(fileURL);
}

export function getDownloadFileErrorMessage(data) {
    return new Promise((resolve) => {
        let fileReader = new FileReader();
        fileReader.onload = function () {
            let jsonData = JSON.parse(this.result);
            resolve(jsonData);
        };
        fileReader.readAsText(data);
    });
}

export function chunkArr(array, size = 1) {
    const length = array == null ? 0 : array.length;
    if (!length || size < 1) {
        return [];
    }
    let index = 0;
    let resIndex = 0;
    const result = new Array(Math.ceil(length / size));

    while (index < length) {
        result[resIndex++] = array.slice(index, (index += size));
    }
    return result;
}
