import { asyncRoutes, constantRoutes } from "@/router";
import menuRoutes from "@/router/modules/menu";


/**
 * @description 判断是否有权限
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
    if (route.meta && route.meta.roles) {
        return roles.some((role) => route.meta.roles.includes(role));
    } else {
        return true;
    }
}

/**
 * 通过递归过滤异步路由表
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
    const res = [];

    routes.forEach((route) => {
        const tmp = { ...route };
        if (hasPermission(roles, tmp)) {
            if (tmp.children) {
                tmp.children = filterAsyncRoutes(tmp.children, roles);
            }
            res.push(tmp);
        }
    });

    return res;
}

const state = {
    routes: [],
    addRoute: [],
    menuRoutes: [],
};

const mutations = {
    SET_ROUTES: (state, routes) => {
        state.addRoute = routes;
        state.routes = constantRoutes.concat(routes);
    },
    SET_MEAU_ROUTES: (state, routes) => {
        state.menuRoutes = routes;
    },
};

const actions = {
    generateRoutes({ commit }, roles) {
        return new Promise((resolve) => {
            const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
            commit("SET_ROUTES", accessedRoutes);
            commit(
                "SET_MEAU_ROUTES",
                menuRoutes.children,
            );
            resolve(accessedRoutes);

        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
