// 个人中心列表
import { checkRole } from "./index";
import md5 from "js-md5";
import store from "../store";

var localtype = md5("role");
const storeMenus = localStorage.getItem(localtype);
export default [
    {
        route: "/user/userinfo",
        children: null,
        title: "基本信息",
        icon: "el-icon-user",
    },
    {
        route: "/user/acset",
        children: [
            {
                route: "/user/acset",
                children: null,
                title: "账户设置",
                icon: "el-icon-setting",
            },
            // {
            //     route: "/user/vip",
            //     children: null,
            //     title: "会员中心",
            //     icon: "el-icon-medal-1"
            // }
        ],
        title: "个人中心",
        img: require("@/assets/img/menu/user_center.png"),
        imgSelect: require("@/assets/img/menu/user_center_select.png"),
    },
    {
        route: "/pay/charge",
        children: [
            {
                route: "/pay/charge",
                children: null,
                title: "充值中心",
                img: require("@/assets/img/menu/charge.png"),
                imgSelect: require("@/assets/img/menu/charge_select.png")
            },
            {
                route: "/pay/chargeHistory",
                children: null,
                title: "充值记录",
                img: require("@/assets/img/menu/charge_history.png"),
                imgSelect: require("@/assets/img/menu/charge_history_select.png")
            },
            {
                route: "/pay/accountRecord",
                children: null,
                title: "消费记录",
                img: require("@/assets/img/menu/consume_history.png"),
                imgSelect: require("@/assets/img/menu/consume_history_select.png")
            },
            {
                route: "/pay/ticket",
                children: null,
                title: "发票管理",
                img: require("@/assets/img/menu/ticket.png"),
                imgSelect: require("@/assets/img/menu/ticket_select.png"),
                hidden: !store.state.user.loginId || store.state.user.loginId === 'personal'
            }
        ],
        title: "我的财务",
        img: require("@/assets/img/menu/finance.png"),
        imgSelect: require("@/assets/img/menu/finance_select.png"),
    },
    // {
    //     route: "/user/pre-sheet",
    //     children: [
    //         {
    //             route: "/user/pre-sheet",
    //             children: null,
    //             title: "报关行添加",
    //             icon: "el-icon-document-add",
    //         },
    //     ],
    //     title: "预录单设置",
    //     img: require("@/assets/img/menu/pre_sheet.png"),
    //     imgSelect: require("@/assets/img/menu/pre_sheet_select.png"),
    //     hidden: false
    // },
    {
        route: "/user/ent-company",
        children: [
            {
                route: "/user/ent-company",
                children: null,
                title: "企业认证",
                icon: "el-icon-s-cooperation",
            },
            {
                route: "/user/manage-colleague",
                children: null,
                title: "同事管理",
                icon: "el-icon-s-management",
                hidden: !store.state.user.loginId || store.state.user.loginId === 'personal'
            },
            {
                route: "/user/all-company",
                children: null,
                title: "全部企业",
                icon: "el-icon-menu",
            },
        ],
        title: "企业管理",
        img: require("@/assets/img/menu/company.png"),
        imgSelect: require("@/assets/img/menu/company_select.png"),
    },
    // {
    //     route: "/user/admin",
    //     children: null,
    //     title: "企业审核",
    //     icon: "el-icon-s-check",
    //     hidden: !(storeMenus && checkRole("admin"))
    // }, // 已经转移到后台管理去了
    // {
    //     route: "/user/admin/system",
    //     children: null,
    //     title: "系统管理",
    //     icon: "el-icon-s-check",
    //     hidden: !(storeMenus && checkRole("admin"))
    // },
]