<template>
    <el-dialog
        :visible.sync="visibleSync"
        append-to-body
        :close-on-click-modal="false"
        :show-close="false"
        class="note-dialog"
        v-bind="$attrs"
    >
        <slot></slot>
        <div slot="footer">
            <div class="btn-line">
                <el-button
                    type="primary"
                    plain
                    class="cancelBtn"
                    @click="$emit('know')"
                >
                    我知道了
                </el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            visibleSync: false,
        };
    },
    watch: {
        visible(val) {
            this.visibleSync = val;
        },
    },
};
</script>
<style lang="stylus">
.note-dialog{
    padding: 0 !important;
    .el-dialog{
        background-image: url("./imgs/notes-1/bgc.png");
        background-size: 100% 100%;
        border-radius: 10px;
    }
    .el-dialog__header{
        display: none;
    }
    .el-dialog__body{
        padding: 0;
        box-sizing: border-box;
        color:#003333;
    }
    .notice{

        color:#000;
    }
    .center{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bold {
        font-weight: bold;
    }

    .text{
        text-indent: 2em;
    }

    .line{
        line-height: 24px;
        margin: 4px 0;
        span{
            margin: 0 4px;
            color: #b87100;
        }
    }

    .notice-content{
        max-height: 70vh;
        overflow: auto;
        padding: 0 30px;
        padding-bottom:10px;
    }

    .left{
        padding-left: 50px;
    }
    pre{
        margin:0
    }
    .replenish-info{
            display: flex;
            justify-content: flex-end;
            .content{
                display: flex;
                width: fit-content;
                flex-direction: column;
                align-items: flex-start;
                justify-content: space-between;
            }
        }
        .btn-line{
            display:flex;
            justify-content:flex-end;
            margin-top: 20px;
            width : 100%;
            padding-bottom: 20px;
        }
        .cancelBtn {
            color: white;
            background: linear-gradient(to right,#2980b9,#6dd5fa)  !important;
            border-radius: 15px;
            width:120px;
        }
}
</style>
