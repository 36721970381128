"use strict";
import { request } from "@/utils/request";
import { baseEnum } from "./enum";

const BASE_URL = baseEnum[process.env.VUE_APP_API_ENV];

export default {
    post(url, data, options, isForm, isQs) {
        return request(
            url,
            "post",
            data,
            {
                // todo：为了调试
                baseURL: BASE_URL,
                ...options,
            },
            isForm,
            isQs,
        );
    },
    get(url, params, options, isForm, isQs) {
        return request(
            url,
            "get",
            params,
            {
                // todo：为了调试
                baseURL: BASE_URL,
                ...options,
            },
            isForm,
            isQs,
        );
    },
    delete(url, params, options, isForm, isQs) {
        return request(
            url,
            "delete",
            params,
            {
                // todo：为了调试
                baseURL: BASE_URL,
                ...options,
            },
            isForm,
            isQs,
        );
    },
};
