// 获取token
const tokenKey = "token";
const userInfo = localStorage.getItem("vuex") ? JSON.parse(localStorage.getItem("vuex")).user : {};
export function getToken() {
    return localStorage.getItem(tokenKey);
}

export function saveToken(tokenValue) {
    return localStorage.setItem(tokenKey, tokenValue);
}

export function removeToken() {
    return localStorage.removeItem(tokenKey);
}

// 获取用户companyId
export function getCompanyId() {
    return userInfo.cid || null
}

// 获取用户role
export function getRole() {
    return userInfo.loginId && userInfo.loginId !== 'personal' && userInfo.cid ? 'ENTERPISE' : 'PERSONAL'
}

export function logout() {
    localStorage.removeItem(tokenKey);
    window.$store.commit("user/editUserId", "");
    window.$store.commit("user/editOpenId", "");
    window.$store.commit("user/editToken", "");
    window.$store.commit("user/editLoginId", "personal");
    window.location.reload();
}
