<template>
    <el-dialog
        title="请先绑定个人信息"
        :visible.sync="dialogTableVisible"
        :show-close="closeAble"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :append-to-body="true"
        class="dialog_class_bind"
        @close="handleDialogClose"
    >
        <el-form
            ref="loginFormRef"
            :model="loginForm"
            label-width="95px"
            :rules="loginFormRules"
        >
            <el-form-item label="手机号" prop="userTel">
                <el-input
                    v-model="loginForm.userTel"
                    placeholder="手机号为必填项"
                ></el-input>
            </el-form-item>

            <el-form-item label="短信验证码" prop="noteCode">
                <phone-code
                    type="register"
                    v-model="loginForm.noteCode"
                    :phone="loginForm.userTel"
                    @verifyPhone="verifyLoginForm"
                ></phone-code>
            </el-form-item>
            <el-form-item label="用户名" prop="username">
                <el-input
                    v-model="loginForm.username"
                    maxlength="20"
                    show-word-limit
                    placeholder="用户名为选填项"
                ></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
                <el-input
                    v-model="loginForm.password"
                    type="password"
                    show-password
                    auto-complete="new-password"
                    placeholder="密码为必填项"
                ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="password2">
                <el-input
                    v-model="loginForm.password2"
                    type="password"
                    show-password
                    auto-complete="new-password"
                    placeholder="确认密码为必填项"
                ></el-input>
            </el-form-item>
            <div
                style="
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding-left: 25px;
                "
            >
                <el-form-item prop="state" label-width="0">
                    <el-checkbox-group v-model="loginForm.state">
                        <el-checkbox
                            label="同意服务协议"
                            name="state"
                        ></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <div
                    style="
                        margin-left: 10px;
                        color: #409eff;
                        margin-bottom: 27px;
                    "
                    @click="read"
                >
                    阅读服务协议和隐私协议
                </div>
            </div>
        </el-form>
        <template slot="footer">
            <el-button type="primary" @click="register">提交</el-button>
            <el-button type="info" @click="resetForm">重置</el-button>
        </template>
        <service-dialog
            v-if="serviceDialogVisible"
            ref="ServiceDialog"
        ></service-dialog>
    </el-dialog>
</template>

<script>
import md5 from "js-md5";
import PhoneCode from "@/components/common/PhoneCode";
import { phoneReg } from "@/utils/config";
import ServiceDialog from "./components/ServiceAgr";
import { checkCodes } from "@/api/login";
import { userRegister } from "@/api";
export default {
    name: "RegisterDialog",
    props: ["closeAble"],
    data() {
        var validatePass = (rule, value, callback) => {
            var passwordreg = new RegExp(
                "(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,16}",
            );
            var isValid = passwordreg.test(value);
            // console.log(isValid)
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value.length > 16 || value.length < 8) {
                callback(new Error("密码必须8~16位之间"));
            } else if (isValid != true) {
                callback(new Error("密码必须包含大小写字母和数字"));
            } else {
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.loginForm.password) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            msg: "",
            dialogTableVisible: false,
            phone: "",
            serviceDialogVisible: false,
            // 这是登录表单的数据绑定对象
            loginForm: {
                userTel: "",
                noteCode: "",
                username: "",
                password: "",
                password2: "",
                state: "",
            },

            // 这是表单的验证规则对象
            loginFormRules: {
                // 验证密码是否合法
                password: [
                    {
                        required: true,
                        validator: validatePass,
                        trigger: "blur",
                    },
                ],
                password2: [
                    {
                        required: true,
                        validator: validatePass2,
                        trigger: "blur",
                    },
                ],
                noteCode: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                ],
                userTel: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur",
                    },
                    {
                        min: 11,
                        max: 11,
                        message: "请输入11位手机号码",
                        trigger: "blur",
                    },
                    {
                        pattern: phoneReg,
                        message: "请输入正确的手机号码",
                    },
                ],
                state: [
                    {
                        required: true,
                        message: "请勾选同意协议",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    components: {
        ServiceDialog,
        PhoneCode,
    },
    methods: {
        init() {
            this.dialogTableVisible = true;
        },
        // 点击重置按钮，重置登录表单
        resetLoginForm() {
            this.$refs.loginFormRef.resetFields();
        },

        async register() {
            this.$refs.loginFormRef.validate(async (valid) => {
                // console.log(valid)
                const { userTel, username, password } = this.loginForm;
                const registerData = {
                    phone: userTel,
                    openId: this.$store.state.user.openId,
                    name: username || this.telDeal(userTel),
                    pwd: md5(password),
                };
                if (valid) {
                    if (this.loginForm.noteCode) {
                        this.checkCode().then(async () => {
                            try {
                                await userRegister(registerData);
                                this.$message.success("注册成功");
                                this.dialogTableVisible = false;
                            } catch (err) {
                                console.error(err);
                            }
                        });
                    } else {
                        this.$message.warning("请输入验证码");
                    }
                }
            });
        },

        resetForm() {
            this.$refs.loginFormRef.resetFields();
        },

        async checkCode() {
            const params = new URLSearchParams();
            params.append("phone", this.loginForm.userTel);
            params.append("code", this.loginForm.noteCode);
            return await checkCodes(params);
        },

        verifyLoginForm() {
            this.$refs.loginFormRef.validateField("userTel");
        },

        read() {
            this.serviceDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.ServiceDialog.Init();
            });
        },
        telDeal(tel) {
            if (tel) {
                return "(" + tel.substr(0, 3) + "****" + tel.substr(7, 4) + ")";
            }
        },
        handleDialogClose() {
            this.$emit("on-close");
        },
    },
};
</script>

<style lang="stylus">
.dialog_class_bind {
  border-radius: 16rpx;
  &.el-dialog__wrapper {
    width: 500px;
    min-width: 40rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .el-dialog{
    width: 100%;
  }
  .el-dialog__body{
    padding: 20px 20px 0 20px;
  }
}
.noteCode_input {
    &.noSend{
      .el-input-group__append button.el-button{
        color: #FFF;
        background-color: #409EFF;
        border-color: #409EFF;
      }
    }
  }
</style>
