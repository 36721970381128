<template>
    <div class="note-timeline">
        <RocketHeaderDilaog
            width="300px"
            top="20vh"
            :visible.sync="visibleSync"
        >
            <div class="header-title">
                <span style="margin-right: 10px; font-size: 24px">版本</span>
                更新公告
            </div>
            <el-timeline class="time-line">
                <el-timeline-item
                    v-for="(note, index) in notesList"
                    :key="index"
                    :timestamp="note.timestamp"
                    :color="note.color"
                >
                    {{ note.content }}
                    <span @click="handleToDetail(index)" class="detail-text">
                        详情
                    </span>
                </el-timeline-item>
            </el-timeline>
            <div class="btn-line">
                <el-button
                    type="danger"
                    plain
                    class="cancelBtn"
                    @click="visibleSync = false"
                >
                    关闭
                </el-button>
            </div>
        </RocketHeaderDilaog>
        <NotesDialog1 ref="notes1Ref" v-if="notesVisible1"></NotesDialog1>
        <NotesDialog2 ref="notes2Ref" v-if="notesVisible2"></NotesDialog2>
    </div>
</template>
<script>
import DialogFooter from "@/components/layout/DialogFooter.vue";
import RocketHeaderDilaog from "@/components/layout/RocketHeaderDilaog.vue";
import NotesDialog1 from "./notes-1.vue";
import NotesDialog2 from "./notes-2.vue";
export default {
    name: "TimelineNote",
    components: {
        DialogFooter,
        NotesDialog1,
        NotesDialog2,
        RocketHeaderDilaog,
    },
    data() {
        return {
            visibleSync: false,
            notesList: [
                {
                    content: "归大师全面升级等",
                    timestamp: "2024-08-17",
                    color: "#c11c20",
                },
                {
                    content: "新增火币功能等",
                    timestamp: "2024-03-15",
                },
            ],
            dialogHeaderImg: require("@/assets/img/dialogHeader.png"),
            notesVisible1: false,
            notesVisible2: false,
        };
    },
    methods: {
        open() {
            this.visibleSync = true;
        },
        handleToDetail(index) {
            const val = this.notesList.length - index;
            this[`notesVisible${val}`] = true;

            this.$nextTick(() => {
                this.$refs[`notes${val}Ref`].open();
            });
        },
    },
};
</script>
<style lang="stylus" scoped>


.time-line{
    padding-top: 100px;
    padding-left:50px;
    user-select: none;
}

.header-title{
    text-align:center;
    font-size: 20px;
    position:absolute;
    top: -10px;
    right:20px;
    color: #fff;
    font-weight: bold;
}
.btn-line{
    display:flex;
    justify-content:center;
    margin-top: 20px;
    width : 100%;
    padding-bottom: 20px;
}
.cancelBtn {
    color: white;
    background: linear-gradient(to right, #e5917e, #daacb9);
    border-radius: 15px;
    width:120px;
}

.detail-text{
    cursor:pointer;
    color:#c11c20;
    margin-left:10px;
}
</style>
