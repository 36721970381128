<template>
    <el-dialog
        :visible.sync="dialogTableVisible"
        center
        :append-to-body="true"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="false"
        width="440px"
        class="switch-dialog"
    >
        <template slot="title">
            <div class="header-title">
                <div class="logo-img">
                    <img :src="logoImg" alt="" width="70">
                </div>
                <div class="id-role">
                    <div>当前身份</div>
                    <div>
                        <div v-if="radio==='personal'" class="role">
                            <img :src="identIcon" width="16"  />
                           个人
                        </div>
                        <div v-else  class="role">
                            <img :src="companyIcon" width="16"  />
                            企业
                        </div>
                    </div>
                </div>
                <img :src="popupRight" class="pop-right" alt="" width="250">
            </div>
        </template>
        <div class="switchId">
            <div
                v-for="(item, index) in allCompany"
                :key="index"
                class="radio_company"
            >
                <el-radio v-model="radio" :label="item.name" class="radio">
                    <div class="messages">
                        <div style="display: flex; align-items: center">
                            <div style="margin-left: 6px">
                                <p>{{ item.name }}({{
                                        item.role == "Management"
                                            ? "管理员"
                                            : "普通员工"
                                    }})</p>
                                
                            </div>
                            <div style="padding: 2px 20px">
                                <el-card
                                    shadow="never"
                                    :body-style="{ padding: '4px' }"
                                    v-if="optIdentify == item.name"
                                >
                                    当前
                                </el-card>
                                </div>  
                        </div>
                        <div style="display: flex; align-items: center">
                            <el-image
                                style="width: 20px; height: 20px"
                                :src="imageYRZ"
                            ></el-image>
                            <span
                                style="
                                    color: #999;
                                    padding: 0px 6px;
                                "
                            >
                                认证成功
                            </span>
                        </div>
                    </div>
                </el-radio>
            </div>
            <div style="display: flex; align-items: center; padding: 16px 0px" class="radio">
                <el-radio
                    v-model="radio"
                    label="personal"
                    style="display: flex"
                >
                    <div style="margin-left: 6px">个人</div>
                </el-radio>
                <div style="padding: 2px 20px">
                    <el-card
                        shadow="never"
                        :body-style="{ padding: '4px' }"
                        v-if="optIdentify == 'personal'"
                    >
                        当前
                    </el-card>
                </div>
            </div>
        </div>
        <DialogFooter @confirm="identifySure" @cancel="closeDialog" cancelText="关闭" top="20"  />
    </el-dialog>
</template>

<script>
import { getUserSuccRoleAndCompany } from "@/api";
import { getUpdateLog } from "../../api/common";
import { apiCallback } from "../../utils";
import DialogFooter from "@/components/layout/DialogFooter.vue";
export default {
    data() {
        return {
            imageYRZ: require("@/assets/img/login/authComIcon.png"),
            logoImg: require("@/assets/img/login/logo.png"),
            popupRight:require("@/assets/img/login/pupup_right.png"),
            identIcon: require("@/assets/img/login/identIcon.png"),
            companyIcon: require("@/assets/img/login/companyIcon.png"),
            test: "测试信息",
            dialogTableVisible: false,
            radio: "personal",
            id: 0,
            allCompany: [],
            optIdentify: "",
        };
    },
    components: {
        DialogFooter
    },
    mounted() {},
    methods: {
        init() {
            this.id = this.$store.state.user.userId;
            this.radio = this.$store.state.user.loginId;
            this.optIdentify = this.radio;
            this.getAllCompanyInfo();
            this.dialogTableVisible = true;
        },
        //个人全部企业信息
        async getAllCompanyInfo() {
            var that = this;
            getUserSuccRoleAndCompany()
                .then((res) => {
                    const data = apiCallback(res);
                    that.allCompany = [];
                    data.forEach((item) => {
                        let company = {};
                        company.name = item.cname;
                        company.role = item.role;
                        company.cid = item.cid;
                        that.allCompany.push(company);
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        //确定登录身份
        identifySure() {
            this.closeDialog();
            this.optIdentify = this.radio;
            const cid =
                this.allCompany.find((item) => item.name === this.radio) &&
                this.allCompany.find((item) => item.name === this.radio).cid;
            this.$store.commit("user/editLoginId", this.optIdentify);
            this.$store.commit("user/editCid", cid);
            localStorage.setItem("loginId", this.optIdentify);
            const curActivePath = this.$router.currentRoute.fullPath;
            window.sessionStorage.setItem("activePath", curActivePath);
            this.checkUpdate();
        },

        // 检查是否有更新
        checkUpdate() {
            const that = this;
            const storageVersion = localStorage.getItem("version");
            const [version, showStatus] = storageVersion
                ? storageVersion.split("-")
                : ["", "0"];
            getUpdateLog()
                .then((res) => {
                    const data = apiCallback(res);
                    if (showStatus === "0") {
                        localStorage.setItem("version", `${data.version}-0`);

                        if (data.version !== version) {
                            setTimeout(() => {
                                that.$alert(data.note, "维护通知", {
                                    confirmButtonText: "确定",
                                    callback: () => {
                                        localStorage.setItem(
                                            "version",
                                            `${data.version}-1`,
                                        );
                                    },
                                });
                            }, 1000);
                        } else {
                            window.location.reload();
                        }
                    } else {
                        window.location.reload();
                    }
                })
                .catch(() => {
                    console.log("获取更新日志失败");
                    window.location.reload();
                });
        },
        closeDialog() {
            this.dialogTableVisible = false;
        },
    },
};
</script>
<style lang="stylus" scoped>
.radio{
    /deep/ .el-radio__input.is-checked .el-radio__inner{
        background-color: #de5d40;
        border-color: #de5d40;
    }
    /deep/ .el-radio__input.is-checked+.el-radio__label{
        color: #de5d40;
    }
}
.header-title{
    background: linear-gradient(to right, #de5d40, #e5917e, #daacb9);
    height:65px; 
    border-radius:10px 10px 0 0;
    position:relative;
    .logo-img{
        position:absolute;
        left:20px;
        top:-30px;
        width:80px;
        height:80px;
        border-radius:50%;
        background-color:white;
        display:flex;
        align-items:center;
        justify-content:center;
    }
    .id-role{
        position:absolute;
        left:120px;
        color:white;
        top:5px;
        font-size:16px;
        display:flex;
        flex-direction:column;
        align-items:flex-start;
        .role{
            padding-top:10px;
            display:flex;
            align-items:center;
            font-size:14px;
            img{
                margin-right:5px;
            }
        }
    }
    .pop-right{
        position:absolute;
        right:0;
    }
}
.switch-dialog{
    /deep/ .el-dialog{
        border-radius: 10px;
    }
}
.switch-dialog{
    /deep/.el-dialog__header {
        padding: 0
      }
} 

.switch-dialog {
 /deep/.el-dialog__body {
  text-align: initial;
  padding: 30px 25px 30px;
  background:linear-gradient(#ffb4b6, 3%, white, white, white, white) !important;
  border-radius: 10px;
  margin-top: -15px;
}
}

.switchId {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 10px;
  border-bottom: 2px solid #f0f0f0;
}

.radio_company {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.radio {
  display: flex;
  align-items: center;
  padding: 2px 0px;
}

.messages {
  display: flex;
  width: 366px;
  align-items: center;
  justify-content: space-between;
}
</style>
