// 预录单接口
import { request } from "@/utils/request.js"
import { axios6Enum } from "./enum";
const FEST_URL = axios6Enum[process.env.VUE_APP_API_ENV]
/**
 * 查询预录单
 * url:`/pre/list/ctnNo`||`/pre/list/entryId`||`/pre/list/billNo`|||`/pre/list/consignorCname`||`/pre/list/workNo`||'/pre/list/notes'
 * params:{
 *    agentCode:string;
 *    oldData?:boolean;
 *    ctnNo?:string;
 *    entryId?:string;
 *    billNo?:string;
 *    consignorCname?:string;
 *    startDate?:string;
 *    endDate?:string;
 *    workNo?:string;
 *    notes?:string;
 * }
 * @description 查询预录单
 * @typedef {Object} params
 * @property {string} params.agentCode - 报关行代码
 * @property {boolean} [params.oldData] - 是否是90天以前数据
 * @property {string} [params.ctnNo] - 集装箱号
 * @property {string} [params.entryId] - 报关单号
 * @property {string} [params.billNo] - 提单号
 * @property {string} [params.consignorCname] - 发货人
 * @property {string} [params.startDate] - 开始日期
 * @property {string} [params.endDate] - 结束日期
 * @property {string} [params.workNo] - 工作号
 * @property {string} [params.notes] - 备注
 * @param {string} url
 * @param {params} params 查询信息参数
 * @returns
 */
export function queryPreSheet(url, params) {
    return request(url, "post", params, { baseURL: FEST_URL }, true);
}
/**
 * 查询委托书
 * @param {string} agentCode
 * @param {string} keyword
 * @returns
 */
export function queryAgr(params) {
    return request("agr/query", "post", params, { baseURL: FEST_URL }, true);
}

// 报关行代码读取报关行名称
export function getAgentName(agentCode) {
    return request(`user/getAgentName?agentCode=${agentCode}`, "get", "", {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        baseURL: FEST_URL
    });
}

/** 
 *  创建pdf文件
 *  @param {string} entryId,
    @param {string} fileName,
    @param {string} fileType
 * 
 * */
export function createPdf(params) {
    return request("pdf/create/pdf", "post", params, {
        baseURL: FEST_URL,
    });
}

/**
 *  批量创建pdf文件，打包zip
 *  @param {Array} [{entryId:string,fileName:string,fileType:srting}],
 *
 * */
export function createPdfList(params) {
    return request("pdf/create/zip", "post", params, { baseURL: FEST_URL });
}
/**
 * 删除pdf
 * @param {string} entryId
 * @returns
 */
export function deletePdfList(params) {
    return request("/pdf/delete/pdf", "post", params, { baseURL: FEST_URL });
}


/**
 * 批量更新预录单
 * @param {string} agentCode
 * @param {string} company
 * @param {number} isDelete
 * @param {number} id
 * @param {string} shortName
 * @param {number} sort
 */
export function updatePreSheet(params) {
    return request("/preData/batchUpdates", "post", params, { baseURL: FEST_URL })
}

/** 
 * 根据用户id查预录单信息
*/

export function queryPreSheetByUserId() {
    return request("/preData/getPreDataByUserId", "get", "", {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
        baseURL: FEST_URL
    })
}

