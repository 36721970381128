import Vue from "vue";
import Router from "vue-router";
import menuRoutes from "./modules/menu";
import userRoutes from "./modules/user";
import advRoutes from "./modules/adv";
import commonRoutes from "./modules/common";
import mainEnter from "./modules/main_enter";
Vue.use(Router);

/**

/**
* hidden: true                   如果设置了hidden，那么该页面将不再显示，路由仍存在,主要用于菜单栏页面
* alwaysShow: true               如果设置为true，将始终显示根菜单，无论它的子路由长度如何
                                 如果不设置alwaysShow，只设置一条以上的子路径
                                 它将成为嵌套模式，否则不显示根菜单
* name:'router-name'             用于 <keep-alive> (必须设置)
* meta : {
    roles: ['admin','staff']     权限(不输入默认都有)
    title: 'title'               页面标题
    noCache: true                页面将不缓存，每次都请求
  }
**/
// 常规路由   如果需要处理权限将对应路由添加到异步路由去
export const constantRoutes = [
    {
        path: "/404",
        name: "NotFound",
        component: () => import("@/components/error-pages/404"),
    },
    {
        path: "/401",
        name: "HasNoAccess",
        component: () => import("@/components/error-pages/401"),
    },
    // 入口路由
    ...mainEnter,
    // 菜单栏
    menuRoutes,
    // 通用 (例如卡车地图)
    ...commonRoutes,
    // userRoutes,
    {
        path: "/adminLogin",
        name: "AdminLogin",
        component: () => import("@/views/Login/AdminLogin"),
    },

    {
        path: "/detail",
        name: "Detail",
        component: () => import("@/views/Logistics/components/Detail"),
    },

    {
        path: "/wxlogin",
        name: "WXLogin",
        component: () => import("@/views/Login/WXLogin"),
    },
    {
        path: "/wxshipmap",
        name: "Wxshipmap",
        component: () => import("@/views/WXHandle/Wxshipmap"),
    },
    {
        path: "/wxtruckmap",
        name: "Wxtruckmap",
        component: () => import("@/views/WXHandle/Wxtruckmap"),
    },
    {
        path: "/invite",
        name: "Invite",
        component: () => import("@/views/user/components/Invite"),
    },
];

export const router = new Router({
    mode: "history", // 需要服务端支持(发布到oss需要把这个注释掉)
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
});
// 异步路由(需要权限控制的路由);
export const asyncRoutes = [
    // 广告
    ...advRoutes,
    // 用户
    userRoutes,
    // 404必须放在最后，不然没法重定向
    { path: "*", redirect: "/404", hidden: true },
];
// export const asyncRoutes = [];
