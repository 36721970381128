<template>
    <NotesDialog
        width="750px"
        :visible.sync="visibleSync"
        top="5vh"
        @know="handleKnow"
    >
        <div class="notice">
            <div class="center"><h1>版本更新</h1></div>
            <div class="notice-content">
                <div class="line">致各位放舱网的用户：</div>
                <div class="line text">
                    为感谢各位一直以来的支持，放舱网现新增厦门口岸的物流跟踪订阅,归大师全面升级与宁波口岸订阅提升免费订阅额度服务
                </div>
                <div class="bold line">1.新增厦门口岸的物流跟踪订阅功能</div>
                <div><img src="./imgs/notes-2/1.png" width="700" /></div>
                <div class="line bold">2.归大师全面升级</div>
                <div class="line text">
                    我们发现大家对于归大师的使用需求越来越多，为了更好的服务大家，我们对归大师进行了全面升级，新增
                    <span class="bold">类注章注快捷查询</span>
                    以及支持
                    <span class="bold">HS CODE查询</span>
                    等功能。
                </div>
                <div><img src="./imgs/notes-2/2.png" width="700" /></div>
                <div class="bold line">3.宁波口岸订阅免费额度添加</div>
                <div class="line text">
                    有许多客户来反馈宁波口岸在多个拼票订阅的时候免费额度不够用，综合考虑之下，现宁波口岸免费订阅额度由原先的
                    <span class="bold">50票/天</span>
                    提升至
                    <span class="bold">100票/天</span>
                    。
                </div>
                <div class="line text">
                    最后感谢各位用户一如即往的支持，也请大家多多提宝贵意见，放舱网也会继续始终如
                    一地为大家提供优质的服务。祝愿各位一切顺利。
                </div>
                <div class="replenish-info">
                    <div class="content">
                        <span>放舱网</span>
                        <span>2024年8月17日</span>
                    </div>
                </div>
            </div>
        </div>
    </NotesDialog>
</template>
<script>
import NotesDialog from "./NotesDialog.vue";
export default {
    components: {
        NotesDialog,
    },
    data() {
        return {
            visibleSync: false, // 是否显示弹窗
            curHeight: 750,
        };
    },
    mounted() {
        const isKnowNotes = localStorage.getItem("isKnowNotes-2");
        if (isKnowNotes !== "true") {
            this.visibleSync = true;
        }
    },
    methods: {
        handleKnow() {
            localStorage.setItem("isKnowNotes-2", "true");
            this.visibleSync = false;
            // this.$refs.scrollNoticeRef.handleScrollShrink()
        },
        open() {
            this.visibleSync = true;
        },
    },
};
</script>
<style lang="stylus">
</style>
