<template>
    <div id="app">
        <router-view></router-view>
        <!-- <error-notice ref="errorNoticeRef"></error-notice> -->
    </div>
</template>

<script>
// import ErrorNotice from './components/common/ErrorNotice.vue';
export default {
    name: "App",
    // components: {
    //     ErrorNotice
    // },
    // mounted() {
    //     this.$refs.errorNoticeRef.open();
    // }
    mounted() { 
        window.onresize = () => { 
            this.$store.commit('other/updateScreenWidth',document.body.clientWidth)
        }
        this.$store.commit('other/updateScreenWidth',document.body.clientWidth)
    }
};
</script>

<style lang="stylus">
.el-scrollbar .el-scrollbar__bar {
      opacity: 1 !important;
 }
 .danger-btn.el-button--text{
    color: #F56C6C;
}

.el-notification.right{
    right:calc(50% - 165px);
    .el-notification__content{
        margin:0;
        p{
            font-size:16px;
            font-weight:700;
            color:#303133
        }
    }
}
</style>
