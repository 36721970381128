<template>
    <div>
        <!-- 设置微信推送弹窗 -->
        <el-dialog
            :visible.sync="dialogTableVisible"
            center
            :append-to-body="true"
            :show-close="false"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            class="wechat_login_dialog"
        >
            <div class="ticket_div" v-if="loginFlag == 0">
                <div class="header">
                    <p class="title-class">微信登录/一键注册</p>
                    <!-- <i class="el-icon-s-platform icon-class" @click="loginFlag=1"></i> -->
                    <img
                        src="@/assets/img/diannao.png"
                        class="icon-class-diannao"
                        @click="loginFlag = 1"
                    />
                </div>
                <el-image :src="qrcode"></el-image>
                <div class="dialog-bottom">
                    <img src="@/assets/img/saoyisao.png" />
                    <p class="p-class">请使用微信扫一扫完成登录/注册</p>
                </div>
            </div>
            <div class="ticket_div" v-if="loginFlag == 1">
                <div class="header">
                    <div class="login-chance">
                        <p
                            @click="loginOpt = 0"
                            :class="{ 'font-color': loginOpt == 0 }"
                        >
                            手机登录
                        </p>
                        <!-- <p @click="loginOpt = 1" :class="{ 'font-color': loginOpt == 1 }">
                用户名登录
              </p> -->
                    </div>
                    <img
                        src="@/assets/img/erweima.png"
                        class="icon-class-erweima"
                        @click="loginFlag = 0"
                    />
                </div>
                <div class="input-class">
                    <el-input
                        v-if="loginOpt == 0"
                        v-model="loginInform.userTel"
                        style="width: 300px"
                        @keyup.enter.native="toNextInput"
                        placeholder="请输入手机号"
                    ></el-input>

                    <!-- <el-input
              v-if="loginOpt == 1"
              v-model="loginInform.username"
              style="width: 300px"
              placeholder="请输入用户名"
            ></el-input> -->

                    <el-input
                        v-model="loginInform.password"
                        style="width: 300px"
                        show-password
                        ref="passwordInput"
                        placeholder="请输入密码"
                        @keyup.enter.native="passwordLogin"
                    ></el-input>
                    <el-button
                        type="primary"
                        style="width: 300px"
                        @click="passwordLogin"
                        :loading="mobileLoginButtonLoading"
                    >
                        登录
                    </el-button>
                </div>
                <el-row
                    type="flex"
                    justify="end"
                    style="width: 100%; padding: 0 20px"
                >
                    <!-- <p class="register-link" @click="openRegisterDialog">注册账号</p> -->
                    <!-- <el-checkbox v-model="autoLogin" disabled>下次自动登录</el-checkbox> -->
                    <p class="forget-password" @click="forgetPwd">忘记密码?</p>
                </el-row>
            </div>
            <div
                class="returnToPreSheet"
                v-if="backPath"
                @click="returnRouter()"
            >
                返回预录单模块
            </div>
        </el-dialog>
        <!-- 注册框 -->
        <register-dialog
            v-if="registDialogVisible"
            ref="RegisterDialog"
            :close-able="true"
            @on-close="handleRegistDialogClose"
        ></register-dialog>
        <forgetPwd-dialog
            v-if="forgetDialogVisible"
            ref="ForgetPwdDialog"
        ></forgetPwd-dialog>
        <switchId-dialog
            v-if="switchIdDialogVisible"
            ref="SwitchIdDialog"
        ></switchId-dialog>
    </div>
</template>

<script>
import RegisterDialog from "@/views/Register/Register";
import ForgetPwdDialog from "@/views/user/components/acset/components/forgetPwd";
import SwitchIdDialog from "./SwitchIdDialog";
import md5 from "js-md5";
import { getCurrentUser, getWxQrCode, wxScanLogin, pwdLogin } from "@/api";
import { userIsRegister } from "@/api";
import { queryPreSheetByUserId } from "@/api/preSheet";
import { preSheetRouter } from "@/utils/permission";
import { apiCallback, checkCurUrlCanSkipLogin } from "../../utils";

export default {
    data() {
        return {
            backPath: "",
            test: "测试信息",
            ticket: "",
            qrcode: "",
            tempid: "",
            loginInterval: "",
            dialogTableVisible: false,
            forgetDialogVisible: false,
            switchIdDialogVisible: false,
            loginFlag: 0, //二维码?账号登录
            loginOpt: 0, //手机?用户名登录
            loginInform: {
                userTel: "",
                // username: '',
                password: "",
            },
            autoLogin: false,
            registDialogVisible: false,
            registDialogCloseAble: false,
            reTryNum: 0, // 重新获取用户信息的次数（用户第一次注册直接获取用户信息会失败，需要通过isRegister获取token后重试）
            mobileLoginButtonLoading: false,
        };
    },
    components: {
        RegisterDialog,
        ForgetPwdDialog,
        SwitchIdDialog,
    },
    mounted() {
        window.loginDialog = this.init;
        this.giftAccount();
    },
    watch: {
        loginFlag: function (val) {
            if (val == 1) {
                this.clTime();
            } else if (val == 0) {
                this.checkLogin();
            }
        },

        $route(to, from) {
            if (from.path.includes("preSheet")) {
                this.backPath = from.path;
            } else {
                if (this.backPath) {
                    this.backPath = "";
                }
            }
        },
    },
    methods: {
        giftAccount() {
            // 如果是测试环境，自动填充账号密码
            if (
                process.env.VUE_APP_API_ENV === "test" ||
                process.env.VUE_APP_API_ENV === "development"
            ) {
                this.loginInform.userTel = "15267077911";
                this.loginInform.password = "Fangzehua1";
            }
        },
        init() {
            if (this.$route.path.includes("preSheet")) {
                return;
            }
            // 1000*60*60*24*7
            const tokenTime = new Date().getTime();
            if (
                localStorage.getItem("tokenTime") &&
                localStorage.getItem("token") &&
                tokenTime - localStorage.getItem("tokenTime") <
                    1000 * 60 * 60 * 24 * 7
            ) {
                this.saveToken(localStorage.getItem("token"));
                this.saveLoginId(localStorage.getItem("loginId")); // 当前登录身份
                this.getUserInfo(false);
            } else {
                if (checkCurUrlCanSkipLogin()) {
                    return;
                }
                this.loginFlag = 0;
                this.loginOpt = 0;
                this.registDialogVisible = false;
                this.dialogTableVisible = true;
                this.loginInform = {
                    userTel: "",
                    // username: '',
                    password: "",
                };
                this.giftAccount();
                this.getLoginQrcode();
            }
        },

        returnRouter() {
            this.closeDialog();
            this.$router.push(this.backPath);
        },
        // 处理预录单路由
        handlePreSheetRouter() {
            queryPreSheetByUserId().then(({ data }) => {
                if (data.data && data.data.constructor == Array) {
                    preSheetRouter(data.data);
                }
            });
        },
        saveLoginId(loginId) {
            this.$store.commit("user/editLoginId", loginId);
        },
        saveLoginInfo(data, type) {
            const tokenTime = new Date().getTime();
            localStorage.setItem("tokenTime", tokenTime);
            const { token, id, openid } = data;
            if (type === "scan") {
                const role = md5(data.role + "/" + data.id);
                var localtype = md5("role");
                localStorage.setItem(localtype, role);
                localStorage.setItem("token", token);
                this.saveToken(token);
                this.saveUserId(id);
            } else if (type === "pwd") {
                const role = md5(data.role + "/" + data.id);
                var localtype = md5("role");
                localStorage.setItem(localtype, role);
                localStorage.setItem("token", token);
                this.saveToken(token);
                this.saveUserId(id);
            }
            this.saveOpenId(openid);
            this.getUserInfo();
            // 处理预录单路由
            // this.handlePreSheetRouter();
        },
        //获取微信二维码
        getLoginQrcode() {
            var that = this;
            getWxQrCode().then((response) => {
                const { data, code } = response.data;
                if (code === 200) {
                    that.ticket = data.ticket;
                    that.tempid = data.tempid;
                    that.qrcode =
                        "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" +
                        that.ticket;
                    that.checkLogin();
                } else {
                    that.$message.error(data.msg);
                }
            });
        },
        scanLogin() {
            var that = this;
            wxScanLogin({ id: that.tempid })
                .then((response) => {
                    const { code, data } = response.data;
                    if (code === 200 && data !== null) {
                        const { openId, id } = data;
                        this.saveOpenId(openId);
                        this.saveUserId(id);
                        that.clTime();
                        that.judgeIsRegister(id);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async judgeIsRegister(id) {
            var that = this;
            // 判断是否注册
            await userIsRegister({ userId: id })
                .then(async (res) => {
                    // data不为null，说明已经注册，去获取用户信息
                    const data = apiCallback(res);
                    this.saveLoginInfo(data, "scan");
                })
                .catch((err) => {
                    console.error(err);
                    that.registDialogVisible = true;
                    that.$nextTick(() => {
                        that.$refs.RegisterDialog.init();
                    });
                    that.closeDialog();
                });
        },
        // 用户名密码登录
        passwordLogin() {
            if (!this.loginInform.userTel || !this.loginInform.password) {
                this.$message.error("用户名/密码不能为空");
                return;
            }
            var that = this;
            let data = {
                account: this.loginInform.userTel,
                password: md5(this.loginInform.password),
            };
            this.mobileLoginButtonLoading = true;
            pwdLogin(data)
                .then((response) => {
                    const token = apiCallback(response);
                    localStorage.setItem("token", token);
                    this.saveToken(token);
                    that.$message.success("登录成功");
                    clearInterval(this.loginInterval);
                    that.loginInform = {
                        userTel: "",
                        password: "",
                    };
                    that.getUserInfo();
                })
                .catch((err) => console.error(err))
                .finally(() => {
                    this.mobileLoginButtonLoading = false;
                });
        },
        //获取用户基本信息
        getUserInfo(needSwitch = true) {
            var that = this;
            this.mobileLoginButtonLoading = true;
            this.$store.dispatch('user/updateUserInfo').then(res => { 
                if (needSwitch) {
                    that.showSwitchIdDialog();
                }
                that.closeDialog();
            }).catch((err) => {
                const curReTryNum = that.reTryNum + 1;
                that.reTryNum = curReTryNum;
                if (curReTryNum < 5) {
                    // 重试次数过多不再重试，避免死循环
                    that.judgeIsRegister(this.$store.state.user.userId);
                } else {
                    console.error(err);
                }
            })
            .finally(() => {
                this.mobileLoginButtonLoading = false;
            });
        },
        showSwitchIdDialog() {
            this.switchIdDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.SwitchIdDialog.init();
            });
        },
        openRegisterDialog() {
            this.registDialogVisible = true;
            this.registDialogCloseAble = true;
            this.$nextTick(() => {
                this.$refs.RegisterDialog.init();
            });
        },
        handleRegistDialogClose() {
            this.registDialogCloseAble = false;
            this.$nextTick(() => {
                this.judgeIsRegister(this.$store.state.user.userId);
            });
        },
        //忘记密码
        forgetPwd() {
            this.forgetDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.ForgetPwdDialog.init();
            });
        },
        //定时执行
        checkLogin() {
            var that = this;
            this.loginInterval = setInterval(function () {
                that.scanLogin();
            }, 2000);
        },
        clTime() {
            clearInterval(this.loginInterval);
        },
        closeDialog() {
            clearInterval(this.loginInterval);
            this.dialogTableVisible = false;
        },
        //保存token
        saveToken(token) {
            this.$store.commit("user/editToken", token);
        },
        saveloginState(code) {
            this.$store.commit("user/editLoginState", code);
        },
        saveUserId(id) {
            this.$store.commit("user/editUserId", id);
        },
        saveOpenId(id) {
            this.$store.commit("user/editOpenId", id);
        },
        removeOpenId() {
            this.$store.commit("user/editOpenId", "");
        },
        removeToken() {
            this.$store.commit("user/editToken", "");
        },
        // 手机号进入下一格密码
        toNextInput(e) {
            e.target.blur();
            this.$refs.passwordInput.focus();
        },
    },
};
</script>
<style lang="stylus" scoped>
.wechat_login_dialog {
  &.el-dialog__wrapper {
    width: 780px;
    height: 1000px;
    min-width: 40rem;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
.header {
  height: 30px;
  width: auto;
}

.title-class {
  font-size: 20px;
  transform: translate(-40%, -150%);
  color: #000;
}

.login-chance {
  margin-left: 40px;
  width: 200px;
  display: flex;
  // justify-content: space-evenly;
  font-size: 16px;
  transform: translate(-35%, -10%);
  color: #ccc;

  .font-color {
    color: #000;
  }
}

.icon-class-diannao {
  margin-left: 10px;
  font-size: 60px;
  transform: translate(340%, -200%);
}

.icon-class-erweima {
  margin-left: 30px;
  font-size: 60px;
  transform: translate(370%, -170%);
}

.returnToPreSheet{
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-family: 500;
    cursor: pointer;
}



.ticket_div {
  width: 340px;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.el-image {
  width: 90%;
  // height: 30rem;
}

img-class {
  font-size: 10px;
}

.dialog-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-class {
  margin-left: 10px;
  width: 120px;
}

.input-class {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 240px;
  margin-top: 20px;
}

.forget-password,.register-link {
  cursor: pointer;
  color: #409EFF;
  user-select: none;
  &:hover{
    opacity :0.8
  }
}
</style>
