// 用于本项目的绑定原型
// 1. 引入实例
import axios from "@/utils/request.js";
import ElementUI from "element-ui";
import Vue from "vue";
import "element-ui/lib/theme-chalk/index.css";
import { videoPlayer } from "vue-video-player";
import "@/styles/index.css";
import "video.js/dist/video-js.css";
import VueParticles from "vue-particles";
import VueClipboard from "vue-clipboard2";
import resetTableNB from "@/data/table";
import qdResetTable from "@/data/table";
import queryTable from "@/data/table";
import queryTable2 from "@/data/table";
import queryTable3 from "@/data/table";
import shopCompanyList from "@/data/table";
import sResetTable from "@/data/table";
import shipTable from "@/data/table";
import shipTableSH from "@/data/table";
import shipTableQD from "@/data/table";
import shipTableTJ from "@/data/table";
import LackAccountDialog from "./register-lack-accout-dialog";
import LackFreeAccountDialog from "./register-lack-free-accout-dialog";

Vue.use(VueClipboard); // 复制链接
Vue.use(VueParticles);
Vue.use(ElementUI);
Vue.use(videoPlayer);

// 2. 与Vue实例关联
// 全局修改$message为自定义的Notification
Vue.prototype.$message=ElementUI.Notification
Vue.prototype.$axios = axios.instance;
Vue.prototype.$base_url = axios.BASE_URL;
Vue.prototype.$excel_url = axios.EXCEL_MODEL_URL;
Vue.prototype.$excel = axios.EXCEL;
Vue.prototype.$loading = ElementUI.Loading.service;
Vue.prototype.SHOPCOMPANYLIST = shopCompanyList;
Vue.prototype.$LackAccountDialog = LackAccountDialog.install;
Vue.prototype.$LackFreeAccountDialog = LackFreeAccountDialog.install;

// 自定义事件 按下回车跳入下一格input框
Vue.directive("enterNextInput", {
    inserted: function (el) {
        el.addEventListener("keypress", function (e) {
            e = e || window.event;
            let charcode =
                typeof e.charCode == "number" ? e.charCode : e.keyCode;
            if (charcode === 13) {
                let dom = document.getElementsByTagName("input");
                for (let i = 0; i < dom.length; i++) {
                    if (dom[i] === document.activeElement) {
                        if (i === dom.length) {
                            return;
                        }
                        dom[i + 1].focus();
                        return;
                    }
                }
            }
            return;
        });
    },
});
// // 自定义指令 实现可拖动
Vue.directive("drag", {
    //1.指令绑定到元素上回立刻执行bind函数，只执行一次
    //2.每个函数中第一个参数永远是el，表示绑定指令的元素，el参数是原生js对象
    //3.通过el.focus()是无法获取焦点的，因为只有插入DOM后才生效
    bind: function (el) {},
    //inserted表示一个元素，插入到DOM中会执行inserted函数，只触发一次
    inserted: function (el) {
        el.onmousedown = function (e) {
            var disx = e.pageX - el.offsetLeft;
            var disy = e.pageY - el.offsetTop;
            document.onmousemove = function (e) {
                el.style.left = e.pageX - disx + "px";
                el.style.top = e.pageY - disy + "px";
            };
            document.onmouseup = function () {
                document.onmousemove = document.onmouseup = null;
            };
        };
    },
    //当VNode更新的时候会执行updated，可以触发多次
    updated: function (el) {},
});
Vue.prototype.$messageBox = ElementUI.MessageBox;
Vue.prototype.SHOPCOMPANYLIST = shopCompanyList;
Vue.prototype.RESETTABLE = sResetTable;
Vue.prototype.RESETTABLE = resetTableNB;
Vue.prototype.RESETTABLE = qdResetTable;
Vue.prototype.QUERYTABLE = queryTable;
Vue.prototype.QUERYTABLE2 = queryTable2;
Vue.prototype.QUERYTABLE3 = queryTable3;
