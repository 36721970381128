<template>
    <el-row type="flex">
        <el-input
            v-model="phoneCode"
            placeholder="请输入手机验证码"
            :class="{ noteCode_input: true, noSend: show }"
        ></el-input>
        <el-button type="danger" plain class="pre-btn" style="width:120px" @click="getCode()" :disabled="!show" >
            <span v-show="show">发送验证码</span>
            <span v-show="!show">{{ this.second }} s</span>
        </el-button>
    </el-row>
</template>

<script>
import { sendCode, bindSendCode } from "@/api/login";

export default {
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        type: {
            type: String,
            default: "common", // register-用户绑定时获取验证码
        },
        phone: {
            type: String,
            default: "",
        },
        value: {
            type: String,
        },
    },
    data: () => {
        return {
            show: true,
            second: "60",
        };
    },
    computed: {
        phoneCode: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("change", value);
            },
        },
    },
    methods: {
        verifyPhone(phone) {
            let result = true;
            const phoneReg = new RegExp(
                "/^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/",
            );
            if (!phone || phone.length !== 11 || phoneReg.test(phone)) {
                result = false;
            }
            return result;
        },
        getCode() {
            const that = this;
            if (!this.verifyPhone(that.phone)) {
                that.$emit("verifyPhone");
            } else {
                const params = new URLSearchParams();
                params.append("phone", that.phone);
                const sendCodeApi =
                    that.type === "register" ? bindSendCode : sendCode;
                sendCodeApi(params)
                    .then(() => {
                        that.$message.success(
                            "验证码已发送至您的手机，请注意查收",
                        );
                        that.show = false;
                        const interval = window.setInterval(function () {
                            if (that.second-- <= 0) {
                                that.second = 60;
                                that.show = true;
                                window.clearInterval(interval);
                            }
                        }, 1000);
                    })
                    .catch((err) => {
                        that.show = true;
                        console.error(err);
                    });
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
.noteCode_input {
    &.noSend{
      .el-input-group__append button.el-button{
        color: #FFF;
        background-color: #409EFF;
        border-color: #409EFF;
      }
    }
  }
</style>
