<template>
    <el-dropdown
        size="medium"
        placement="bottom-end"
        trigger="hover"
        @command="handleOptUser"
    >
        <div class="opt_dialog">
            <el-image class="icon_user" :src="userAvatar"></el-image>
            <div :class="[this.$store.state.user.token ? 'username' : '']">
                {{ userName }}
            </div>
            <el-dropdown-menu slot="dropdown" class="header-new-drop">
                <img
                    src="@/assets/img/menu/cirque.png"
                    width="150px"
                    style="position: absolute"
                />
                <div class="infoline">
                    <div class="username">
                        {{ curUserName }}
                    </div>
                    <div class="invite-code">
                        <span style="color: #9eaabd">邀请码：</span>
                        <el-tooltip placement="top">
                            <template #content>
                                <div class="inviteCode-tooltip-content">
                                    <div>点击复制邀请码</div>
                                </div>
                            </template>
                            <span
                                v-clipboard:copy="curInviteCode"
                                v-clipboard:success="copySucc"
                            >
                                {{ curInviteCode }}
                            </span>
                        </el-tooltip>
                    </div>
                </div>
                <div
                    class="company-info"
                    v-if="curCompanyName && curCompanyName !== 'personal'"
                >
                    <div class="name-wrap">
                        <img
                            src="@/assets/img/login/authComIcon.png"
                            style="margin-right: 5px; width: 22px"
                        />
                        <span>{{ curCompanyName }}</span>
                    </div>
                    <div class="role">{{ role }}</div>
                </div>
                <el-dropdown-item
                    v-for="item in OptuserInfo"
                    :command="item.command"
                    :key="item.command"
                >
                    <img width="18" height="18" :src="item.img" />
                    <p style="margin-left: 10px">
                        {{ item.label }}
                    </p>
                </el-dropdown-item>
            </el-dropdown-menu>
            <i class="el-icon-caret-bottom"></i>
        </div>
        <logout-dialog
            v-if="logoutDialogVisible"
            ref="LogoutDialog"
        ></logout-dialog>
        <switchId-dialog
            v-if="switchIdDialogVisible"
            ref="SwitchIdDialog"
        ></switchId-dialog>
    </el-dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import drowMenuList from "@/utils/drop-menu-list";
import LogoutDialog from "@/components/register/LogoutDialog";
import { checkRole } from "@/utils";
import SwitchIdDialog from "@/components/register/SwitchIdDialog";
export default {
    components: { LogoutDialog, SwitchIdDialog },
    data() {
        return {
            OptuserInfo: drowMenuList,
            logoutDialogVisible: false,
            switchIdDialogVisible: false,
        };
    },
    computed: {
        ...mapGetters(["token", "loginId", "avatar", "username"]),
        userName() {
            return this.token && this.loginId == "personal"
                ? this.username
                : this.loginId;
        },
        userAvatar() {
            return this.token && this.avatar
                ? this.avatar
                : require("@/assets/img/icon_user.png");
        },
        curInviteCode() {
            return this.$store.state.user.inviteCode || "";
        },
        curUserName() {
            return this.username || "";
        },
        curCompanyName() {
            return this.loginId || "";
        },
        role() {
            return checkRole("admin") ? "管理员" : "用户";
        },
    },
    methods: {
        copySucc() {
            this.$message.success("复制成功");
        },
        showSwitchIdDialog() {
            this.switchIdDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.SwitchIdDialog.init();
            });
        },
        //个人导航栏转跳
        handleOptUser(index) {
            if (index === "switch_role") {
                //身份切换
                this.showSwitchIdDialog();
            } else if (index === "quit") {
                //退出登录
                this.logout();
            } else {
                const route = this.OptuserInfo.find(
                    (item) => item.command === index,
                ).route;
                this.$router.currentRoute.fullPath != route &&
                    this.$router.push(route);
            }
        },
        //退出登录
        logout() {
            this.logoutDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.LogoutDialog.init();
            });
        },
    },
};
</script>
<style lang="stylus">
.el-popper.header-new-drop {
  .el-dropdown-menu__item:not(.is-disabled):hover {
    background: linear-gradient(90deg, #DE6972 0%, rgba(222,105,114,0) 100%);
    color:#8B0505
  }
}
</style>
<style scoped lang="stylus">
.inviteCode-tooltip-content{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}
.header-new-drop{
    padding:0 !important;
    background-image:url('../../../assets/img/menu/cirque.png')
    background: linear-gradient(180deg, #FFDFDF 0%, #FFFFFF 24%, #FFFFFF 100%);
    li {
        width: 280px;
        color: #9EAABD ;
        font-size:16px;
        line-height:28px;
        display:flex;
        align-items:center;
        position:relative;
        z-index:9999;
    }
    .infoline{
        display: flex;
        justify-content: space-between;
        padding: 0 15px;
        margin-top:20px;
        margin-bottom:10px;
        .invite-code{
            font-size: 14px;
            cursor: pointer;
        }

    }
    .username{
        font-size: 16px;
        line-height: 22px;
        font-family: Inter-Regular, Inter;
    }

    .company-info{
        color:#9EAABD
        font-size: 16px;
        line-height: 22px;
        margin-left:15px;
        margin-bottom:15px;
        font-family: Inter-Regular, Inter;
        height:22px;
        display:flex;
        justify-content: space-between;
        align-items:center;
        .name-wrap{
            display: flex;
            align-items: center;

        }
        .role{
            font-size: 12px;
            padding:0 4px;
            border-radius: 3px;
            width:45px;
            text-align:center;
            color:#A5220E;
            border: 1px solid #A5220E;
            margin-right:15px;
        }
    }

}
.icon_user {
    width: 42px;
    height: 42px;
    object-fit: cover;
    border-radius: 50%;
    background-color: #f2f2f2;
    flex-shrink: 0;
  }

  .username {
    max-width: 120px;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: #333333;
                    font-size: 16px;
                    font-weight: bold;
                    height: 100%;
                    line-height: 42px;
                    margin: 0 8px;
  }




  .opt_dialog {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  padding: 0 2rem;
  z-index: 10;
  }
</style>
