
const state = {
    goodsuuid: "", //上海舱单新增货物明细的uuid
    searchShipData: [],
    AisInfoData: [],
    shipTrackData: [],
    blonBoxInfo: [], // 箱货跟踪信息
    shipInfo: [], // 船期信息
    dockInfo: [], // 预配舱单信息
    aloneDockInfo: [], // 单独查询页面预配舱单信息
    allBoxInfo: [], // 拼箱信息
    boxStatus: [], // 箱货状态
    shipStatus: [], // 船期状态
    portCloseTime: 24, //截关时间设置：
    ctnApplyEndTime: 24, //截港时间设置：
    qdWharfList: [], // 云港通码头信息
};

const mutations = {
    editPorttime(state, payload) {
        state.portCloseTime = payload;
    },
    editGoodsuuid(state, payload) {
        state.goodsuuid = payload;
    },
    editCtnapplyend(state, payload) {
        state.ctnApplyEndTime = payload;
    },
    editSearchShipData(state, payload) {
        state.searchShipData = payload;
    },

    editAisInfoData(state, payload) {
        state.AisInfoData = payload;
    },
    editShipTrackData(state, payload) {
        state.shipTrackData = payload;
    },
    editBlonBoxInfo(state, payload) {
        state.blonBoxInfo = payload;
    },
    editShipInfo(state, payload) {
        state.shipInfo = payload;
    },
    editDockInfo(state, payload) {
        state.dockInfo = payload;
    },
    editAloneDockInfo(state, payload) {
        state.aloneDockInfo = payload;
    },
    editAllBoxInfo(state, payload) {
        state.allBoxInfo = payload;
    },
    editBoxStatus(state, payload) {
        state.boxStatus = payload;
    },
    editShipStatus(state, payload) {
        state.shipStatus = payload;
    },
    editQdWharfList(state, payload) {
        state.qdWharfList = payload;
    }
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
