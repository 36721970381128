// 支付模块接口
import { request } from "@/utils/request.js";
import { axios7Enum } from "./enum";
export const FEST_URL = axios7Enum[process.env.VUE_APP_API_ENV]
const fakeToken = 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIzMDk5IiwiY29tcGFueUlkIjo5LCJwaG9uZSI6IjE1MjY3MDc3OTExIiwibmlja05hbWUiOm51bGwsInVzZXJJZCI6IjMwOTkiLCJpYXQiOjE2ODAxMzg2NDAsImp0aSI6ImQyNGE4NDNkYjY2MjQzMDI4N2Q0NmZkZGQ2MTBjZTIyIn0.dPgxFO2vTo4aajxVeGKorloqucG5UX4T2cr3AmEvSV4'
const headersForm = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Authorization': fakeToken
    },
    baseURL: FEST_URL
}
const headersJson = {
    ...headersForm,
    headers: {
        'Content-Type': 'application/json',
    }
}
// 发票模块
/**
 * @description 分页查我的企业开票记录
 * @param {date-time} endTime 开票截取结束时间
 * @param {int32} invoiceType   发票类型
 * @param {int32} pageNum 当前页
 * @param {int32} pageSize 每页条数
 * @param {date-time} startTime 开票开始时间
 * @param {int32} type 当前状态0开票中,1已开票.2已寄出
 * @returns 
 */
export function getHistoryTickets(param) {
    return request("/companyInvoiceRecord/getCompanyInvoiceRecord", "get", param, headersForm)
}

/**
 * @description 添加修改企业发票信息
 * @param {string} addressee 发票收件人
 * @param {string} bankAccount   税务开户银行账号
 * @param {string} bankDeposit 税务开户银行
 * @param {init32} companyId 公司ID
 * @param {string} contactAddress 收件人详细地址
 * @param {string} contactEmail 收件人联系邮件
 * @param {string} contactPhone 发票收件人联系电话
 * @param {string} registerAddress 税务登记地址
 * @param {string} registerPhone 税务登记电话
 * @param {string} taxIdNumber 税务识别号
 * @param {string} type 0增值税电子发票 1增值税专用发票
 * @returns 
 */
export function addCompanyInvoice(param) {
    return request("/companyInvoice/addCompanyInvoice", "post", param, headersJson)
}

/**
 * @description 查询我的企业发票信息
 * @returns 
 */
export function getCompanyInvoiceByCompanyId() {
    return request("/companyInvoice/getCompanyInvoiceByCompanyId", "get", "", headersForm)
}

/**
 * @description 开票
 * @param {int32} invoiceId 发票id
 * @param {int32} type 发票状态
 * @returns 
 */
export function makeInvoice(param) {
    return request("/companyInvoiceRecord/makeInvoice", "post", param, headersJson)
}

/**
 * @description 导出开票记录
 * @param {date-time} endTime 开票截取结束时间
 * @param {int32} invoiceType   发票类型
 * @param {int32} pageNum 当前页
 * @param {int32} pageSize 每页条数
 * @param {date-time} startTime 开票开始时间
 * @param {int32} type 当前状态0开票中,1已开票.2已寄出
 */
export function exportRecord(param) {
    return request("/companyInvoiceRecord/exportRecord", "post", param, headersForm)
}

/**
 * @description 查看开票记录详情
 * @param {int32} id 发票id
 * @returns 
 */
export function getCompanyInvoiceRecordDetails(param) {
    return request("/companyInvoiceRecord/getCompanyInvoiceRecordDetails", "get", param, headersForm)
}



// 充值模块
/**
 * @description 获取充值商品列表
 */
export function payRechargeGoods(param) {
    return request("/pay/recharge/goods", "get", param, headersForm)
}


/**
 * @description 获取当前用户账户
 */
export function getAccount(param) {
    return request("/user/getAccount", "get", param??'', headersForm)
}


/**
 * @description 下单生成支付连接
 * @param {int32} accountId 选择的充值的账号Id
 * @param {int32} accountType 0普通账户1公司账户
 * @param {int32} goodsId 选择的商品id,金额
 * @param {string} payType 支付方式:WXPAY|ALIPAY
 */
export function rechargeOrderPay(param) {
    return request("/pay/recharge/order/doPay", "post", param, headersForm, true)
}


/**
 * @description 充值记录列表
 * @param {int32} pageNo 当前页
 * @param {int32} pageSize 尺寸
 * @param {string} startTime 开始时间
 * @param {string} endTime 结束时间
 */
export function getRechargeList(param) {
    return request("/pay/recharge/order/rechargeList", "get", param, headersForm)
}
/**
 * @description 分页查流水
 * @param {int32} pageNo 当前页
 * @param {int32} pageSize 尺寸
 * @param {string} startTime 开始时间
 * @param {string} endTime 结束时间
 * @param {int32} accountType 0自己账户1企业账户
 * @param {string} way 变动方式
 */
export function queryAccountRecord(param) {
    return request("/pay/account/record/queryAccountRecord", "get", param, headersForm)
}

/**
 * @description 根据orderNo查询订单
 * @param {string} orderNo 订单编号
 */
export function queryOrderByOrderNo(orderNo) {
    return request("/pay/recharge/order", "get", orderNo, headersForm)
}

/**
 * @description 对公账户充值
 * @param {string|number} amount 金额
 * @param {string} billPicture 转账截图
 * @param {goodId} goodId 商品id
 * @param {string} invoiceId 发票信息id
 * @returns 
 */
export function chargeCompanyAccount(param){
    return request("/pay/recharge/public/create","post",param,headersJson,true)
}