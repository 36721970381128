// 登录部分
import { request } from "@/utils/request.js";
import { axios3Enum } from "./enum";
const LOGIN_URL = axios3Enum[process.env.VUE_APP_API_ENV];
// 获取信息
export function getUserInfoPage(param) {
    return request("v1/GetUserInfoPage", "get", param, { baseURL: LOGIN_URL });
}
//更新权限
export function updateUserSuRole(param) {
    return request("v1/UpdateUserSuRole", "post", param, {
        baseURL: LOGIN_URL,
    });
}
/**
 * 小程序微信登录
 * @param {String} code
 * @returns
 */
export function wxLogin(param) {
    return request("/manifest/wechat/getOpen", "get", param, {
        showMessageError: false,
    });
}

//  退出登录
export function logout() {
    return request(
        "/auth/logOut",
        "post",
        {},
        {
            showMessageError: false,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
    );
}

/**
 * 获取手机验证码
 * @param {string} phone
 */
export function sendCode(param) {
    return request("/message/records/getCode", "post", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * 用户绑定时获取手机验证码
 * @param {string} phone
 */
export function bindSendCode(param) {
    return request("/message/records/binding/getCode", "post", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * 校验手机验证码
 * @param {string} phone
 * @param {string} code
 */
export function checkCodes(param) {
    return request("/message/records/checkCode", "post", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

// 根据电话查
export function searchByPhone(param) {
    return request("/api/company/GetComUserbyphoneOne", "get", param);
}

// 日志
export function queryLog(param) {
    return request("operlog/queryOperlog", "post", param);
}

// 操作说明
export function queryIntroduction() {
    return request("operlog/getOperdesc", "get");
}

// 获取信息
export function queryShipInfo(param) {
    return request("/proxy/getAisInfo", "post", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

// 查找船信息
export function searchShipInfo(param) {
    return request("/proxy/searchShip", "post", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}
