import { request } from "@/utils/request";

export function classifyAnalysis(param) {
    return request("/classify/analysis", "post", param, {}, true, false);
}

export function getHsCodeByGName(param) {
    return request("classify/analysis/list", "post", param, {}, true, false);
}

/**
 * hs编码模糊查询
 * @param {String} code
 */
export function getHsCodeVague(data) {
    return request(
        "/hsData/query",
        "post",
        data,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
    );
}

/**
 * hsCode详情
 * @param {String} hsCode
 */
export function getHsCodeDetail(data) {
    return request(
        "/hsData/info",
        "post",
        data,
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
        },
        true,
    );
}

/**
 * hscode类目结构
 */
export function getHsCodeCategoryTree() {
    return request("/hsData/tax", "get");
}

/**
 * hsCode企业实例
 * @param {String} hsCode
 */
export function getHscodeCompanyCase(params) {
    return request("/hsData/queryCompanyCase", "get", params, {
        showErrorMessage: false,
    });
}

/**
 * 批量查询hsCode
 * @param {String} codeStr // hsCode(多个用,分隔)
 * @param {Number} current
 * @param {Number} size
 */
export function batchGetHscodeList(params) {
    return request("/hsData/queryBatchList", "get", params);
}

/**
 * 类注、章注、子类注释
 * @param {Number} id
 */
export function getHsCodeTaxNote(params) {
    return request("/hsData/taxNote", "get", params);
}

/**
 * 中文品名查找历史申报要素
 * @param {String} keyword
 * @param {Number} current
 * @param {Number} pageSize
 */
export function getHsElementByGName(params) {
    return request("/hsData/queryElement", "get", params);
}
