<template>
    <!-- <ScrollNotice
        v-if="visibleSync"
        @shrink-end="visibleSync = false"
        append="notice-tips"
        ref="scrollNoticeRef"
        :height="curHeight"
    >
        <template slot="info">
            <div class="notice">
                <div class="center"><h1>温馨提示</h1></div>
            <pre class="line">
            致各位放舱网的用户：
            &nbsp;&nbsp;由于最近网站常有被恶意爬数据的情况发生，导致服务器负担的增加，造成了一定的困
            扰。故对每天宁波口岸的订阅量做了票数的限制，这个票数在正常使用场景下是完全够用的。
            同时也新增了上海口岸的物流跟踪订阅服务，每周有3票的免费使用额度。
            1、新增上海口岸的物流跟踪订阅功能
        </pre
            >
            <div class="left"><img src="./imgs/notes-1/1.png" /></div>
            <pre class="line">
            2、新增火币功能，每1火币=1元人民币，每个账户分别设置上海免费余额和宁波免费余额。
            上海免费额度：3票/周，超出收取1火币/票，每周一0点会重置免费余额为：3火币。
            宁波免费额度：50票/天，超出收取1火币/票，每天0点会重置免费余额为：50火币。
            在有充值火币的情况下，所有的订阅会优先使用免费余额，在免费余额为0的情况下才会使
            用充值余额。
        </pre
            >
            <div class="left"><img src="./imgs/notes-1/2.png" /></div>
            <pre class="line">
            &nbsp;&nbsp;最后感谢各位用户一如即往的支持，也请大家多多提宝贵意见，放舱网也会继续始终如
            一地为大家提供优质的服务。祝愿各位一切顺利。
        </pre
            >
            <div class="replenish-info">
                <div class="content">
                    <span>放舱网</span>
                    <span>2024年3月15日</span>
                </div>
            </div>
           <div style="display:flex;justify-content:center;width:100%;margin-bottom: 15px;">
            <el-button @click="handleKnow" type="success" size="small">
                    已阅
                </el-button></div>
               
            </div>
        
        </template>
    </ScrollNotice> -->
    <NotesDialog
        width="750px"
        :visible.sync="visibleSync"
        top="5vh"
        @know="handleKnow"
    >
        <div class="notice">
            <div class="center"><h1>温馨提示</h1></div>
            <div class="notice-content">
                <div class="line">致各位放舱网的用户：</div>
                <div class="line text">
                    由于最近网站常有被恶意爬数据的情况发生，导致服务器负担的增加，造成了一定的困
                    扰。故对每天宁波口岸的订阅量做了票数的限制，这个票数在正常使用场景下是完全够用的。
                    同时也新增了上海口岸的物流跟踪订阅服务，每周有
                    <span class="bold">3票</span>
                    的免费使用额度。
                </div>
                <div class="bold line">1.新增上海口岸的物流跟踪订阅功能</div>
                <div><img src="./imgs/notes-1/1.png" /></div>
                <div class="line bold">2.新增火币功能</div>
                <div class="line text">
                    <span class="bold">每1火币=1元人民币</span>
                    ，每个账户分别设置上海免费余额和宁波免费余额。
                    上海免费额度：
                    <span class="bold">3票/周</span>
                    ，超出收取1火币/票，每周一0点会重置免费余额为：3火币。
                    宁波免费额度：
                    <span class="bold">50票/天</span>
                    ，超出收取1火币/票，每天0点会重置免费余额为：50火币。
                    在有充值火币的情况下，所有的订阅会优先使用免费余额，在免费余额为0的情况下才会使
                    用充值余额。
                </div>
                <div><img src="./imgs/notes-1/2.png" /></div>
                <div class="line text">
                    最后感谢各位用户一如即往的支持，也请大家多多提宝贵意见，放舱网也会继续始终如
                    一地为大家提供优质的服务。祝愿各位一切顺利。
                </div>
                <div class="replenish-info">
                    <div class="content">
                        <span>放舱网</span>
                        <span>2024年3月15日</span>
                    </div>
                </div>
            </div>
        </div>
    </NotesDialog>
</template>
<script>
import ScrollNotice from "../common/ScrollNotice.vue";
import NotesDialog from "./NotesDialog.vue";
export default {
    components: {
        ScrollNotice,
        NotesDialog,
    },
    data() {
        return {
            visibleSync: false, // 是否显示弹窗
            curHeight: 750,
        };
    },
    mounted() {
        const isKnowNotes = localStorage.getItem("isKnowNotes-1");
        if (isKnowNotes !== "true") {
            this.visibleSync = true;
        }
    },
    methods: {
        handleKnow() {
            localStorage.setItem("isKnowNotes-1", "true");
            this.visibleSync = false;
            // this.$refs.scrollNoticeRef.handleScrollShrink()
        },
        open() {
            this.visibleSync = true;
        },
    },
};
</script>
