<template>
    <el-dialog
        title="免费余额不足提醒"
        :visible.sync="visibleSync"
        width="580px"
        append-to-body
        class="lack-money-dialog"
        :close-on-click-modal="false"
        center
    >
        <div style="display: flex; justify-content: center">
            <img :src="moneyImg" alt="" width="150" />
        </div>
        <div
            style="
                display: flex;
                justify-content: center;
                font-size: 18px;
                color: #c11c20;
            "
        >
            免费额度不足,{{ propsParam && propsParam.msg }}
        </div>
        <div slot="footer">
            <div style="display: flex; align-items: center; padding-left: 20px">
                <el-checkbox v-model="ignoreTips" style="margin-right: 80px">
                    今日不再提醒
                </el-checkbox>
                <DialogFooter
                    @confirm="handleConfirm"
                    @cancel="handleClose"
                    top="0"
                    style="justify-content: start"
                />
            </div>
        </div>
    </el-dialog>
</template>
<script>
import DialogFooter from "@/components/layout/DialogFooter.vue";
export default {
    name: "lack-free-account-dialog",
    data() {
        return {
            visibleSync: false, // 是否显示弹窗
            propsParam: null, // 传入参数
            ignoreTips: false, //今日不再提醒
            moneyImg: require("../../assets/img/money/money.svg"),
        };
    },
    components: {
        DialogFooter,
    },

    methods: {
        handleClose() {
            this.visibleSync = false;
        },
        handleConfirm() {
            // 执行传入的方法
            this.propsParam && this.propsParam.func && this.propsParam.func();
            if (this.ignoreTips) {
                const curDay =
                    new Date().getMonth() + 1 + "-" + new Date().getDate();
                const parseObj = {
                    ...this.propsParam.parseJson,
                    [this.propsParam.showTab]: curDay,
                };
                // 清除其他的
                Object.keys(parseObj).forEach((key) => {
                    // console.log(key)
                    if (key !== "NB" && key !== "SH") {
                        delete parseObj[key];
                    }
                });
                const parseStr = JSON.stringify(parseObj);
                localStorage.setItem("isShowFreeBalanceTips", parseStr);
            }
            this.visibleSync = false;
        },
        open(param) {
            this.propsParam = param;
            // 显示
            this.visibleSync = true;
        },
        cancel() {
            // 关闭
            this.visibleSync = false;
        },
    },
};
</script>
<style scoped lang="stylus">
.lack-money-dialog{
    /deep/ .el-dialog{
        border-radius: 10px;
    }
    /deep/ .el-dialog__header{
        background:linear-gradient(to right, #de5d40, #e5917e, #daacb9)
        border-radius: 10px 10px 0 0;
    }
    /deep/ .el-dialog__body{
        padding:0;
        padding-bottom:20px;
    }
    /deep/ .el-dialog__title{
        color: rgb(154, 31, 18);
        font-weight: 530;
        font-size: 24px
    }
    /deep/ .el-dialog__headerbtn .el-dialog__close{
        font-size:20px;
        color:rgb(154, 31, 18);
    }
}

.cancelBtn {
    color: white !important;
    background: linear-gradient(to right, #e5917e, #daacb9) !important;
    border-radius: 15px;
    width:120px;
}
</style>
