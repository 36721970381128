const state = {
    recruitmentInfo: "",
    preSheetList: [],
    countryCode: [],
    expandUserMenu: []
}

const mutations = {
    // 设置公司招聘信息用于查看
    SET_RECRUITMENT_INFO(state, payload) {
        state.recruitmentInfo = payload.length ? JSON.parse(payload) : payload
    },
    editPreSheetListState(state, payload) {
        state.preSheetList = payload
    },
    editCountryCode(state, payload) {
        state.countryCode = payload
    },
    editExpandUserMenu(state, payload) {
        state.expandUserMenu = payload
    }
}

const actions = {

}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}