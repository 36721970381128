import "@/utils/import";
import Vue from "vue";
import "@/utils/permission";
import App from "./App.vue";
import { router } from "./router/index.js";
import store from "@/store";

Vue.config.productionTip = false;
window.$store = store;
window.$router = router;

new Vue({
    router,
    store,
    render: (h) => h(App),
    created: function () {
        const storageToken = localStorage.getItem("token");
        this.$store.commit("user/editToken", storageToken);
        this.$store.dispatch("user/updateInviteCode");
    },
}).$mount("#app");
