import Layout from "@/components/layout/Layout";
import payRoutes from "./pay";
import user from "@/views/user";
export default {
    path: "/",
    component: Layout,
    name: "user",
    children: [
        {
            path: "/user",
            component: user,
            children: [
                {
                    path: "/user/userinfo",
                    component: () => import("@/views/user/components/userinfo"),
                    name: "userInfo",
                },
                {
                    path: "/user/acset",
                    component: () => import("@/views/user/components/acset"),
                    name: "acSet",
                },
                {
                    path: "/user/pre-sheet",
                    component: () =>
                        import("@/views/PreSheet/components/PreSheetAdd"),
                    name: "pre-sheet",
                },
                ...payRoutes,
                {
                    path: "/user/ent-company",
                    component: () =>
                        import("@/views/user/components/entcompany"),
                    name: "ent-company",
                },
                {
                    path: "/user/manage-colleague",
                    component: () =>
                        import("@/views/user/components/managecolleague"),
                    name: "manage-colleague",
                },
                {
                    path: "/user/vip",
                    component: () => import("@/views/VIPMember"),
                    name: "vip",
                },
                {
                    path: "/user/all-company",
                    component: () =>
                        import("@/views/user/components/allcompany"),
                    name: "all-company",
                },
            ],
        },
    ],
};
