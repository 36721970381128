<!-- 布局 -->
<template>
    <div id="app">
        <el-container>
            <Header />
            <app-main />
            <el-footer class="footer-center">
                <a
                    href="https://www.beian.gov.cn/portal/registerSystemInfo?spm=a21bo.jianhua.1997523009.39.5af92a89VpB9nV&recordcode=33021202002094"
                    class="footer-line"
                    style="margin-right: 8px"
                >
                    <span class="footer-info">浙公网安备 33021202002094号</span>
                </a>
                <a href="https://beian.miit.gov.cn/" class="footer-line">
                    <span class="footer-info">浙ICP备2022012639号-1</span>
                </a>
                <span class="footer-info" style="margin-left: 24px;">版本信息：v{{ version }}</span>
            </el-footer>
            <fix-tool-bar />
        </el-container>
    </div>
</template>
<script>
import { version } from '../../../package.json';
import Header from "./components/Header";
import AppMain from "./components/AppMain";
import FixToolBar from "../common/FixToolBar.vue";

export default {
    data() {
    return {
          version
        };
    },
    mounted() {
        if (!this.$store.state.other) {
            this.$store.commit("other/editSystemKey", "NB");
        }
    },
    components: {
        Header,
        AppMain,
        FixToolBar,
    },
    methods: {},
};
</script>
<style scoped lang="stylus">
.el-main {
  padding: 0;
  padding-top: 70px;
}

.el-dropdown {
  height: 4rem;
}

.el-menu {
  &.page_header_menu{
    margin-left: 20px;
    border-bottom: none;
    .hvr-bounce-to-bottom {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: perspective(1px) translateZ(0);
      transform: perspective(1px) translateZ(0);
      box-shadow: 0 0 1px rgba(0, 0, 0, 0);
      position: relative;
      border-radius: 4px;
      overflow: hidden;
      color: #333333;
      border: none;
      font-size:18px;
      font-weight:bold;
      overflow: hidden;
      &.is-active{
        border-radius: 4px 4px 0 0;
        border-bottom: 2px solid #409eff;
      }
    }
    .hvr-bounce-to-bottom:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgb(193,194,197);
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 50% 0;
      transform-origin: 50% 0;
      -webkit-transition-property: transform;
      transition-property: transform;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transition-timing-function: ease-out;
      transition-timing-function: ease-out;
    }
    .hvr-bounce-to-bottom:hover, .hvr-bounce-to-bottom:focus, .hvr-bounce-to-bottom:active {
      color: #333333;
    }
    .hvr-bounce-to-bottom:hover:before, .hvr-bounce-to-bottom:focus:before, .hvr-bounce-to-bottom:active:before {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }

    ::v-deep.hvr-bounce-to-bottom .el-submenu__title{
      color:#333333;
      font-size:18px;
      font-weight:bold;
    }

    .el-menu--horizontal {
      border-bottom: 0px;
    }

  }
}

.menu_div {
  display: flex;
  margin-right: 5rem;
}

.user_div {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5rem;
  width: fit-content;
  padding: 0 1rem;

  p {
    // color:#fff !important;
    margin-left: 1rem;
  }
}

.user_div:hover {
  background-color: var(--RED-C11C20);

  p {
    color: #fff;
  }
}

.user_div-f {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
}

.icon_user {
  width: 42px;
  height: 42px;
  object-fit: cover;
  border-radius: 50%;
  background-color: #f2f2f2;
  flex-shrink: 0;
}

.username {
  width: 100px;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.opt_dialog {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: fit-content;
  padding: 0 2rem;
  z-index: 10;
}
.view {
  display: none;
  position:absolute;
  bottom:0;
  left: 50%;
  width:140px;
  height: 140px;
  background: rgb(26,33,45);
  transform: translate(-50%,100%);
}
.view img {
  width: 140px;
  height: 140px;
}


// footer的css
.el-footer {
    height: 100%;
}
.footer-center {
    padding: 20px 0;
    width: 600px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.footer-line {
    text-decoration: none;
}

.footer-info {
    margin: 0px 0px 0px 20px;
    color: #939393;
}
</style>
