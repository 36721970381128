import { request } from "@/utils/request";

/**
 * 舱单发送记录
 */

export const getShFileSendRecord = (data) => {
    return request("/shManifest/hymanifestshhead/fcw/showlist", "post", data, {
        // baseURL: "http://192.168.1.92:8111",
    });
};

/**
 * 发送上海舱单补充信息
 * @param {String} billno
 * @param {String} remark
 * @param {String} uuid
 */
export const sendShManifestExtraInfo = (data) => {
    return request(
        "/shManifest/hymanifestshhead/fcw/fcwmanifestData",
        "post",
        data,
        {
            // baseURL: "http://192.168.1.92:8111",
        },
        true,
    );
};

/**
 * 导出舱单excel
 * @param {Number} id
 */
export const exportShManifestExcel = (param) => {
    return request(
        "/shManifest/hymanifestshhead/fcw/exportexcel",
        "get",
        param,
        {
            responseType: "blob",
            // baseURL: "http://192.168.1.92:8111",
        },
    );
};

/**
 * 获取海管家船公司和船代
 */
export const getShipCompanyAndAgent = () => {
    return request("/shManifest/hymanifestshhead/fcw/getHaiGuanJiaDict", "get");
};
