import { request } from "@/utils/request.js";
// 提空箱popover出发时获取数据
export function queryTakeout(boxBlno) {
    return request(`/boxlist/queryTakeout?blno=${boxBlno}`, "get");
}

// 获取船信息
export function queryVesselsShip(param) {
    return request(`vessels/getShip`, "post", param, {}, true);
}

// 获取全部的提单号用于搜索
export function queryBlno(param) {
    return request(`blno/userBlno/queryBlno`, "get", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}
// 获取上海全部的提单号用于搜索
export function queryBlnoSH(param) {
    return request("/blno/userBlnoSh/queryBlno", "get", param);
}

//获取全部的船名航次用于搜索
export function queryVevo(param) {
    return request(`blno/userBlno/queryVeVo`, "get", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}
//获取上海全部的船名航次用于搜索
export function queryVevoSH(param) {
    return request(`/blno/userBlnoSh/queryVeVo`, "get", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * 分页查箱单
 * @param {string} blno
 * @param {int} ctnApplyBeginFlag // 开港状态
 * @param {int} ctnApplyEnd // 截港标识
 * @param {int} ctnApplyEndFlag //截港状态
 * @param {int} customFlag // 海关放行
 * @param {string} start // 开始日期
 * @param {string} end // 截止日期
 * @param {string} queryFlag // 时间选项
 * @param {int} nppStatus // 码头放行
 * @param {int} pageNum
 * @param {int} pageSize
 * @param {int} portClose // 截单标识
 * @param {int} portCloseFlag // 截单状态
 * @param {string} vesselsName // 船名
 * @param {string} voyage // 航次
 * @returns
 */
export function queryBoxList(param) {
    return request("/blno/userBlno/queryBlnoPage", "post", param);
}
/**
 * 分页查箱单
 * @param {string} blno
 * @param {int} ctnApplyBeginFlag // 开港状态
 * @param {int} ctnApplyEnd // 截港标识
 * @param {int} ctnApplyEndFlag //截港状态
 * @param {int} customFlag // 海关放行
 * @param {string} start // 开始日期
 * @param {string} end // 截止日期
 * @param {string} queryFlag // 时间选项
 * @param {int} nppStatus // 码头放行
 * @param {int} pageNum
 * @param {int} pageSize
 * @param {int} portClose // 截单标识
 * @param {int} portCloseFlag // 截单状态
 * @param {string} vesselsName // 船名
 * @param {string} voyage // 航次
 * @returns
 */
export function queryBoxListSH(param) {
    return request("/blno/userBlnoSh/queryBlnoPage", "post", param);
}

/**
 * 置顶
 * @param {Array} ids
 */
export function toTop(ids) {
    return request("/blno/userBlno/toppingList", "post", ids);
}

// 取消置顶
export function cancelToTop(ids) {
    return request("/blno/userBlno/unToppingList", "post", ids);
}

//集卡定位添加新车
export function addNewCarPosition(param) {
    return request("boxlist/subscribe", "post", param, {}, true);
}

//订阅通用方法
export function orderBoxTicket(url, param, options) {
    return request(url, "post", param, options);
}

// 历史记录
export function deleteCarPosition(param) {
    return request("blno/userBlno/queryDeleted", "post", param);
}

// 上海历史记录
export function deleteHistorySH(param) {
    return request("blno/userBlnoSh/queryDeleted", "post", param);
}

export function getGpsTruck(param) {
    return request("/blno/port/v2/getGpsTruck", "get", param, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}

/**
 * 单次订阅判断账户余额是否充足
 * @param {number} companyId 公司id
   @param {string} origin 类型NB，SH,
   @param {string} payAccountMoneyEnum 消费类型  FEE_BLNO提单号订阅/FEE_NB_VESSEL宁波船期订阅/FEE_VESSEL上海船期订阅
   @param {string} role 当前用户角色 PERSONAL/ENTERPISE
*/

export function verFreeBalanceEn(param) {
    return request("/pay/account/user/verFreeBalanceEn", "post", param, {
        showErrorMessage: false,
    });
}
