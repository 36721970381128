// 青岛船期
import { request } from "@/utils/request.js";


/**
 * @description 青岛船期订阅
 * @typedef {Object} param 
 * @property {string} param.code 类型,NB,SH,QD,这里是青岛
 * @property {string} param.companyId 公司id
 * @property {string} [param.remark] 备注
 * @property {string} param.role 角色 当前用户角色/PERSONAL/ENTERPISE
 * @property {string} param.vesselsName 船名
 * @property {string} param.voyage 航次
 * @param {param} param
 * @returns 
 */
export const qdShipQuery=(param)=>{
    return request("/vessels/qdVessels/subscribe",'post',param)
}

/**
 * @description 青岛船期列表分页查询
 * @typedef {Object} pageVesselsDTO
 * @property {string} pageVesselsDTO.code 类型,NB,SH,QD,这里是青岛
 * @property {number} pageVesselsDTO.current 当前页
 * @property {number} pageVesselsDTO.size 每页显示条数
 * @property {string} [pageVesselsDTO.vesselsName] 船名
 * @property {string} [pageVesselsDTO.voyage] 航次
 * @property {string} [pageVesselsDTO.startTime] 开始时间
 * @property {string} [pageVesselsDTO.endTime] 结束时间
 * @property {string} [pageVesselsDTO.ctnApplyBeginTime] 是否是今日开港
 * @property {string} [pageVesselsDTO.ctnApplyEndTime] 是否是今日截港
 * @property {string} [pageVesselsDTO.closeTime] 是否是今日截单/截关
 * @property {string} [pageVesselsDTO.arrivalTime] 是否是今日抵港
 * @property {string} [pageVesselsDTO.leaveTime] 是否是今日离港
 * @param {pageVesselsDTO} param 
 */
export const qdShipListPageQuery=(param)=>{
    return request("/vessels/qdVessels/selectPage",'post',param)
}


/**
 * @description 获取已订阅的船名航次
 * @typedef {Object} param 
 * @property {string} param.code 类型,NB,SH,QD,这里是青岛
 * @param {param} param
 */

export const qdShipSubscribed=(param)=>{
    return request("/vessels/qdVessels/queryVeVo",'get',param,{
        header:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    })
}


/**
 * @description 批量删除
 * @param {Array<number>} ids
 */
export const qdShipBatchDelete=(ids)=>{
    return request("/vessels/qdVessels/deleteList",'post',ids)
}


/**
 * @description 批量置顶
 * @param {Array<number>} ids
 */
export const qdShipBatchTop=(ids)=>{
    return request("/vessels/qdVessels/toppingList",'post',ids)
}

/**
 * @description 取消置顶
 * @param {Array<number>} ids
 */
export const qdShipBatchCancelTop=(ids)=>{
    return request("/vessels/qdVessels/unToppingList",'post',ids)
}

/**
 * @description 修改备注
 * @param {number} id
 * @param {string} remark
 */
export const qdShipUpdateRemark=(param)=>{
    return request("/vessels/qdVessels/updateRemark",'post',param,{
        header:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    })
}

/**
 * @description 获取云港通码头信息
 */
export const qdWharfList=()=>{
    return request("/blno/userBlnoQd/getYgtWharfList",'get')
}

/**
 * @description 修改码头
 * @param {string} vesselsName 船名
 * @param {string} voyage 航次
 * @param {string} wharf 码头
 */
export const qdWharfUpdate=(param)=>{
    return request("/vessels/qdVessels/refreshQdVesselByWharf",'get',param,{
        header:{
            'Content-Type':'application/x-www-form-urlencoded'
        }
    })
}