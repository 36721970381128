<template>
    <div v-if="!item.hidden">
        <!-- 渲染普通菜单 -->
        <el-menu-item
            v-if="!item.children || !(item.children.length > 0)"
            :index="resolvePath(item.route)"
            class="hvr-bounce-to-bottom"
        >
            <div class="icon-contain">
                <i v-if="item.icon" :class="item.icon"></i>
                <img
                    v-else-if="item.img && activePath !== item.route"
                    :src="item.img"
                    class="img"
                />
                <img
                    v-else-if="item.imgSelect && activePath === item.route"
                    :src="item.imgSelect"
                    class="img"
                />
            </div>
            {{ item.title }}
        </el-menu-item>
        <el-submenu
            v-else
            ref="subMenu"
            :index="resolvePath(item.children[0].route)"
            popper-append-to-body
        >
            <template slot="title">
                <div
                    style="width: 100%; display: flex; align-items: center"
                    @click="handleChildrenPush(item.children[0].route)"
                >
                    <div class="icon-contain">
                        <i v-if="item.icon" :class="item.icon"></i>

                        <img
                            v-else-if="
                                item.img &&
                                !item.children
                                    .map((item) => item.route)
                                    .includes(activePath)
                            "
                            :src="item.img"
                            class="img"
                        />
                        <img
                            v-else-if="
                                item.imgSelect &&
                                item.children
                                    .map((item) => item.route)
                                    .includes(activePath)
                            "
                            :src="item.imgSelect"
                            class="img"
                        />
                    </div>

                    {{ item.title }}
                </div>
            </template>
            <template>
                <el-menu-item
                    class="hvr-bounce-to-bottom"
                    v-for="submenu in item.children"
                    :key="submenu.route"
                    v-if="!submenu.hidden"
                    :index="resolvePath(submenu.route)"
                >
                    <template slot="title">
                        <div class="icon-contain">
                            <i v-if="submenu.icon" :class="submenu.icon"></i>
                            <img
                                v-else-if="
                                    submenu.img && activePath !== submenu.route
                                "
                                :src="submenu.img"
                                class="img"
                            />
                            <img
                                v-else-if="
                                    submenu.imgSelect &&
                                    activePath === submenu.route
                                "
                                :src="submenu.imgSelect"
                                class="img"
                            />
                        </div>

                        {{ submenu.title }}
                    </template>
                </el-menu-item>
            </template>
        </el-submenu>
    </div>
</template>

<script>
import path from "path";
import AppLink from "./Link";

export default {
    name: "MeanuItem",
    components: { AppLink },
    props: {
        // route object
        item: {
            type: Object,
            required: true,
        },
        isNest: {
            type: Boolean,
            default: false,
        },
        basePath: {
            type: String,
            default: "",
        },
        activePath: {
            type: String,
            default: "",
        },
    },
    methods: {
        resolvePath(routePath) {
            return path.resolve(routePath);
        },
        handleChildrenPush(routePath) {
            if (this.$router.currentRoute.fullPath !== routePath) {
                this.$router.push(routePath);
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
.icon-contain{
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img{
    margin-right: 5px; width: 18px; height: 18px
}
.icon{
    width:18px;
    height:18px;
    color: red;
}
.hvr-bounce-to-bottom {
    width:100%;
    display: flex;
    align-items:center;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    border-radius: 4px;
    color: #333333;
    border: none;
    font-size:18px;
    font-weight:bold;
    overflow: hidden;
    &.is-active{
      border-radius: 2px 2px 0 0;
      border-left: 5px solid #A91520;
      background: linear-gradient(90deg, #DE6972 0%, rgba(222,105,114,0) 100%);
    }
  }

  ::v-deep  .el-submenu__title{
    color:#333333;
    font-size:18px;
    font-weight:bold;
  }

  ::v-deep .is-active .el-submenu__title{
    color:#8B0505 !important;
    & i{
        color:#8B0505 !important;
    }
  }
</style>
