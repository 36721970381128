<!-- 卷轴公告组件 -->
<template>
    <div
        class="container"
        :style="{ width: curWidth + 'px' }"
        ref="containerRef"
    >
        <div class="reel reel-top"></div>
        <div class="info" :style="{ height: curTranslateY + 'px' }">
            <slot name="info"></slot>
        </div>
        <div class="reel reel-bottom"></div>
    </div>
</template>
<script>
export default {
    name: "ScrollNotice",
    props: {
        width: {
            type: Number,
            default: 700,
        }, // 卷轴宽度
        height: {
            type: Number,
            default: 800,
        }, // 卷轴高度
        expandSpeed: {
            type: Number,
            default: 10,
        }, // 展开速度
        shrinkSpeed: {
            type: Number,
            default: 20,
        }, // 收缩速度
        append: {
            type: String,
            default: "",
        }, // 卷轴绑定的节点
        rocateSpeed: {
            type: Number,
            default: 30,
        }, // 旋转速度
    },
    data: () => {
        return {
            curWidth: 0, // 当前卷轴的宽度
            curTranslateY: 0, // 当前卷轴的高度
            curTime: 0, // 当前请求帧时间
            rotateTimes: 0, // 旋转移动次数
            initTranslateY: 0, // 初始高度
        };
    },
    computed: {
        // 获取目标元素的中心点与当前元素的中心点的距离
        appendDomTarget () {
            if (this.append) {
                const target = document
                    .getElementById(this.append)
                    .getBoundingClientRect();
                const centerX = target.left + target.width / 2;
                const centerY = target.top + target.height / 2;
                const curCenterX = window.innerWidth / 2;
                const curCenterY = 40 + 28 / 2; // 因为我设置了top是40px，卷轴高度是28px
                const calY = curCenterY - centerY;
                const calX = curCenterX - centerX;
                return { curCenterX, curCenterY, centerX, centerY, calX, calY };
            }
            return null;
        },
        // 消失所需耗时
        disappearTime(){
            return this.width / this.shrinkSpeed;
        },
    },
    methods: {
        // 展开卷轴
        scrollExpand() {
            this.curTime = requestAnimationFrame(this.scrollExpand);
            const { expandSpeed, height, append, width } = this;
            // 如果当前宽度小于设定的宽度，将宽度增加
            if (append && this.curWidth < width) {
                this.curWidth += expandSpeed;
            } else {
                // 如果当前高度小于设定的高度，将高度增加
                if (this.curTranslateY < height) {
                    this.curTranslateY += expandSpeed;
                } else {
                    cancelAnimationFrame(this.curTime);
                }
            }
            
        },
        handleScrollExpand() {
            cancelAnimationFrame(this.curTime);
            this.curTime = requestAnimationFrame(this.scrollExpand);
        },

        // 收缩卷轴
        scrollShrink() {
            this.curTime = requestAnimationFrame(this.scrollShrink);
            const { shrinkSpeed ,append} = this;
            if (this.curTranslateY > 0) {
                this.curTranslateY -= shrinkSpeed;
            } else {
                if (append && this.curWidth > 0) {
                    this.curWidth -= shrinkSpeed;
                    // 旋转并移动
                    this.handleRotateAndMove();
                } else {
                    cancelAnimationFrame(this.curTime);
                    this.$emit("shrink-end");
                }
            }
        },

        handleScrollShrink() {
            cancelAnimationFrame(this.curTime);
            this.curTime = requestAnimationFrame(this.scrollShrink);
        },

        // 旋转并移动
        handleRotateAndMove() {
            this.rotateTimes++;
            this.$refs.containerRef.style.rotate =
                (this.curTime % 180) * this.rocateSpeed + "deg";
            const { calX, calY } = this.appendDomTarget;
            const frameCalX = calX / this.disappearTime;
            const frameCalY = calY / this.disappearTime;
            // 每一帧移动frameCalX，frameCalY
            const needCalX = this.rotateTimes * frameCalX;
            const needCalY = this.rotateTimes * frameCalY;
            this.$refs.containerRef.style.left = `calc(50% - ${needCalX}px)`;
            this.$refs.containerRef.style.top = `calc(54px - ${needCalY}px)`;
        },
    },
    mounted() {
        if (this.append) {
            this.handleScrollExpand();
            // 居中
            this.$refs.containerRef.style.left = `calc(50% - ${this.width / 2}px)`;
        } else {
            this.curWidth = this.width;
        }
    },
};
</script>
<style scoped>
.container {
    display: flex;
    flex-direction: column;
    top:40px;
    z-index:9999;
    position: fixed;
    max-height: 90%;
    overflow: auto;
}
.reel {
    height: 28px;
    margin: 0 15px;
    border-radius: 1px;
    border-image: url("../../assets/img/notice/scroll.png") fill 40 36/14px 12px/0
        12px;
    box-shadow: 0 5px 10px 5px rgba(0, 0, 0, 0.3),
        0 5px 10px 5px rgba(0, 0, 0, 0.5);
    overflow: hidden;
}

::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 0 rgba(0, 158, 255, 0.46);
    background-color: rgba(0, 158, 255, 0.46);
}

.info {
    background: url("../../assets/img/notice/page.jpg");
    margin: 0 15px;
    overflow: auto;
}
</style>
