<!-- 页面主体 -->
<template>
    <el-main>
        <transition name="fade-transform" mode="out-in">
            <!-- <keep-alive :include="cachedViews"> -->
            <router-view :key="key"></router-view>
            <!-- </keep-alive> -->
        </transition>
    </el-main>
</template>

<script>
export default {
    computed: {
        // 组件中导出name需要和route中的name相同
        cachedViews() {
            // 缓存页面
            return ["BoxQuery"];
            // return this.$store.state.permission.menuRoutes.map(
            //     (item) => item.name === "BoxQuery",
            // );
        },
        key() {
            return this.$route.fullPath;
        },
    },
};
</script>
