<template>
    <div class="f_div">
        <!-- 页面主体区域 -->
        <el-container
            class="home-container"
            style="height: calc(100vh - 120px)"
        >
            <el-aside :width="calWidth">
                <div class="toggle-button" @click="toggleCollapse">|||</div>
                <el-menu
                    background-color="#fff"
                    text-color="#000"
                    active-text-color="#8B0505"
                    :collapse="isCollapse"
                    :collapse-transition="false"
                    :default-openeds="expandUserMenu"
                    :default-active="activePath"
                    @close="handleClose"
                    @open="handleOpen"
                    router
                >
                    <menu-user-item
                        v-for="route in userCenterList"
                        :key="route.path"
                        :item="route"
                        :base-path="route.path"
                        :activePath="activePath"
                    ></menu-user-item>
                </el-menu>
            </el-aside>
            <!-- 右侧内容主体 -->
            <el-main style="margin-left: 26px; padding: 0">
                <transition name="fade">
                    <router-view :key="$route.fullPath"></router-view>
                </transition>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import userCenterList from "@/utils/userList";
import MenuUserItem from "@/components/layout/components/MenuUserItem";
export default {
    components: { MenuUserItem },
    data() {
        return {
            // 是否折叠
            isCollapse: false,
            userCenterList: userCenterList,
            closeTime: null,
        };
    },
    computed: {
        activePath() {
            const route = this.$route;
            const { meta, path } = route;
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            this.handleOpen(path);
            return path;
        },
        calWidth() {
            return this.isCollapse ? "40px" : "260px";
        },
        possibleOpenRouter() {
            return this.userCenterList.filter((item) => {
                return item.children && item.children.length > 0;
            });
        },
        expandUserMenu() {
            return this.$store.state.info.expandUserMenu;
        },
    },
    methods: {
        logout() {
            window.sessionStorage.clear();
            this.$router.push("/login");
        },
        // 点击按钮，切换菜单的折叠与展开
        toggleCollapse() {
            this.isCollapse = !this.isCollapse;
        },
        handleClose(val) {
            const data = [...this.expandUserMenu];
            const newData = data.filter((item) => {
                return item !== val;
            });
            this.closeTime = true;
            this.$store.commit("info/editExpandUserMenu", newData);
        },
        handleOpen(path) {
            if (this.closeTime) {
                return;
            }
            // 判断是否在需要展开的路由中
            const index = this.possibleOpenRouter.findIndex((item) => {
                return item.children.some((item) => {
                    return item.route === path;
                });
            });
            if (~index) {
                const currentRoute = this.possibleOpenRouter[index];
                const data = [...this.expandUserMenu];
                if (!data.includes(currentRoute.route)) {
                    data.push(currentRoute.route);
                    this.$store.commit("info/editExpandUserMenu", data);
                }
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
.home-container {
  position: absolute;
  width:100%;
  padding:20px 77px;
  height: 100%;
  .el-aside {
    height:calc(100vh - 160px);
    position: relative;
    background-color: #fff;
    overflow-x:hidden;
    .el-menu {
      border-right: none;
    }
  }
}

.f_div {
  display: flex;
  justify-content: center;
}

.home-container{
    .el-main{
        background-color: #eaedf1;
    }
}

.iconfont {
  margin-right: 10px;
}

.toggle-button {
  background-color: #4a5064;
  font-size: 10px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}

.fade-enter,
.fade-leave-to{
  visibility: hidden;
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active{
  transition: opacity .2s ease;
}
.fade-enter-to,
.fade-leave{
  visibility: visible;
  opacity: 1;
}
</style>
