import request from "./http";

/**
 * 文件下载
 * @param {string} fileName
 */

export const downLoadFile = async (params) => {
    return await request.post("/api/file/downLoad", params);
};

/**
 * 文件是否存在
 * @param {string} fileName
 */
export const isFileExist = async (params) => {
    return await request.get("/api/file/exists", params);
};

/**
 * 文件上传
 * @param {file} file // formData格式
 */
export const uploadFile = async (params) => {
    return await request.post("/file/upload", params, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
};
